import React, { memo, useCallback, useMemo, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { ActionIcon, Select, SelectProps, Tooltip } from "@mantine/core";
import { IconCheck, IconMessage, IconStar, IconStarFilled } from "@tabler/icons-react";
import { EFontSize, Question } from "@common/types";
import {
  useAddFavoriteQuestionMutation,
  useGetFavoriteQuestionsQuery,
  useRemoveFavoriteQuestionMutation,
} from "@/redux/api";

interface ChatOptionsMessageProps {
  fontSize?: EFontSize;
  message: string;
  options?: string[];
  onClick: (question: string) => void;
}

const iconProps = {
  stroke: 1.5,
  color: "currentColor",
  opacity: 0.6,
  size: 18,
};

const ChatOptionsMessage: React.FC<ChatOptionsMessageProps> = memo(
  ({ fontSize, message, options, onClick }) => {
    const { data: favoriteQuestions } = useGetFavoriteQuestionsQuery(undefined);

    const [addFavoriteQuestion, { isLoading: isAddFavoriteQuestionLoading }] =
      useAddFavoriteQuestionMutation();

    const [removeFavoriteQuestion, { isLoading: isRemoveFavoriteQuestionLoading }] =
      useRemoveFavoriteQuestionMutation();

    const processingQuestionRef = useRef<string>("");

    const questions = useMemo(
      () =>
        favoriteQuestions?.reduce(
          (acc, favoriteQuestion) => acc.set(favoriteQuestion.question, favoriteQuestion),
          new Map()
        ) || new Map(),
      [favoriteQuestions]
    );

    const fontSizeClassMap: Record<EFontSize, string> = {
      xs: "prose-xs",
      sm: "prose-sm",
      base: "prose-base",
      lg: "prose-lg",
      xl: "prose-xl",
    };

    const elementSize = fontSize === "base" ? "md" : fontSize;

    const handleFavoriteQuestionClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>, option: string, question?: Question) => {
        event.stopPropagation();

        processingQuestionRef.current = option;

        if (question) {
          removeFavoriteQuestion({ question_id: question.id });
        } else {
          addFavoriteQuestion({ question: option });
        }
      },
      [addFavoriteQuestion, removeFavoriteQuestion]
    );

    const isFavoriteQuestionProcessing =
      isAddFavoriteQuestionLoading || isRemoveFavoriteQuestionLoading;

    const renderSelectOption: SelectProps["renderOption"] = ({ option, checked }) => {
      const favoriteQuestion = questions.get(option.value);

      return (
        <div className="grid grid-cols-[1.5rem_1fr_auto_auto] justify-self-center items-center gap-2 w-full">
          <ActionIcon
            variant="transparent"
            size="1.2rem"
            loading={isFavoriteQuestionProcessing && processingQuestionRef.current === option.value}
            onClick={(event) => handleFavoriteQuestionClick(event, option.value, favoriteQuestion)}
          >
            {favoriteQuestion ? (
              <Tooltip label="Remove from Favorite Questions" openDelay={1000}>
                <IconStarFilled color="plum" />
              </Tooltip>
            ) : (
              <Tooltip label="Add to Favorite Questions" openDelay={1000}>
                <IconStar color="mediumPurple" />
              </Tooltip>
            )}
          </ActionIcon>
          <span className="pt-1">{option.label}</span>
          <div className="self-center">
            {checked && <IconCheck style={{ marginInlineStart: "auto" }} {...iconProps} />}
          </div>
        </div>
      );
    };

    return Array.isArray(options) && options.length ? (
      <div className="bg-white p-2 rounded-tr-xl rounded-br-xl rounded-bl-xl">
        <div
          className={`prose ${fontSizeClassMap[fontSize || "base"]} prose-strong:text-[--mantine-color-text] text-[color:var(--mantine-color-text)] pl-1 py-1 [overflow-wrap:anywhere]`}
        >
          <ReactMarkdown>{message}</ReactMarkdown>
        </div>
        <div className="py-4">
          <Select
            label="Response options"
            size={elementSize}
            radius="md"
            placeholder="Select response"
            data={[...new Set(options)]} // Remove duplicates, otherwise UI can crash
            onChange={(value) => (value ? onClick(value) : null)}
            maxDropdownHeight={300}
            comboboxProps={{ transitionProps: { transition: "pop", duration: 100 } }}
            leftSection={<IconMessage size={18} />}
            renderOption={renderSelectOption}
          />
        </div>
      </div>
    ) : null;
  }
);

ChatOptionsMessage.defaultProps = {
  fontSize: EFontSize.base,
  onClick: () => {},
};

export default ChatOptionsMessage;
