import { useEffect, useMemo, useState } from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Match, OnHighlightKeyword, searchPlugin } from "@react-pdf-viewer/search";
import { renderToolbar } from "./components";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { LocalStorageService } from "@/services";
import {
  getIsDocumentLoaded,
  getSelectedDocument,
  getDocuments,
  setIsDocumentLoaded,
  getIsRedactView,
  getIsSelectedDocumentRedacted,
} from "@/redux/slices/documents.ts";
import { pushNotification } from "@/redux/slices/notifications.ts";
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts";
import { getQuestionText } from "@/redux/slices/qa.ts";
import { getMiddleIndex, prepareSearchFragmentRegex, splitStringByWhitespaces } from "@/utils";

import { Text, useMantineColorScheme } from "@mantine/core";

export const ReactPDFViewer = () => {
  const [url, setUrl] = useState<string>(" ");

  const [isTheFirstDocumentLoad, setIsTheFirstDocumentLoad] = useState<boolean>(false);

  const appDispatch = useAppDispatch();

  const documents = useAppSelector(getDocuments);

  const isDocumentLoaded = useAppSelector(getIsDocumentLoaded);

  const isRedactView = useAppSelector(getIsRedactView);

  const isSelectedDocumentRedacted = useAppSelector(getIsSelectedDocumentRedacted);

  const selectedDocument = useAppSelector(getSelectedDocument);

  const questionText = useAppSelector(getQuestionText);

  const { colorScheme } = useMantineColorScheme();

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar,
  });

  const searchPluginInstance = searchPlugin({
    onHighlightKeyword: (props: OnHighlightKeyword) => {
      // Adjust highlighted element background so that the currently selected
      // search result background is the same as other search results / lines
      // https://react-pdf-viewer.dev/examples/add-custom-styles-to-the-highlighted-elements/
      props.highlightEle.style.backgroundColor = "rgba(255, 255, 0, 0.4)";
    },
  });

  const { highlight, clearHighlights } = searchPluginInstance;

  useEffect(() => {
    if (selectedDocument) {
      const document = selectedDocument?.children?.[0] ?? selectedDocument;

      if (isSelectedDocumentRedacted) {
        setUrl(isRedactView ? document.redacted_url ?? " " : document.display_url);
      } else {
        setUrl(document.display_url);
      }

      appDispatch(setIsDocumentLoaded(true));
    } else {
      setIsTheFirstDocumentLoad(true);
      setUrl(" ");
    }
  }, [selectedDocument, isRedactView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!questionText) {
      clearHighlights();
      return;
    } else {
      // Extends the search word by word until a match is no longer found
      // When no matches are found, highlight the current match(es) and return
      const extendSearchMatch = async (
        searchWords: string[] = [],
        currentCutoffIndex: number
      ): Promise<Match[]> => {
        const currentSearchRegex = prepareSearchFragmentRegex(searchWords, currentCutoffIndex);
        const nextCutoffIndex = currentCutoffIndex + 1;
        const nextSearchRegex = prepareSearchFragmentRegex(searchWords, nextCutoffIndex);
        const nextMatches = await highlight(nextSearchRegex);
        if (nextMatches?.length && nextCutoffIndex <= searchWords?.length) {
          return await extendSearchMatch(searchWords, nextCutoffIndex);
        }
        const matches = await highlight(currentSearchRegex);
        if (matches?.length) {
          appDispatch(
            pushNotification({
              type: "info",
              message: "Found multiple corresponding text fragments. Highlighting all of them.",
            })
          );
        }
        return matches;
      };

      // Make a dynamic search by iteratively looking up the relevant text in the document
      // If more than one result is found, repeat the search with a smaller set of words
      // If no results are found, repeat the search with a larger set of words
      // Use an approach similar to binary search to find the maximum relevant text possible
      const performSearch = (
        searchWords: string[],
        currentMinIndex: number,
        currentMaxIndex: number
      ): void => {
        // If no words to search for, give notification and return
        if (currentMaxIndex - currentMinIndex === 0) {
          appDispatch(
            pushNotification({
              type: "info",
              message: "Could not automatically find reference in the currently open document.",
            })
          );
          return;
        }
        const searchRegex = prepareSearchFragmentRegex(searchWords, currentMaxIndex);
        highlight(searchRegex).then((matches) => {
          const matchCount = matches?.length;
          if (matchCount === 1) {
            extendSearchMatch(searchWords, currentMaxIndex);
          } else {
            if (matchCount > 1) {
              // If we only have a few words left or if we have multiple matches with the whole set of words,
              // just let `extendSearchMatch` handle the results
              if (
                currentMaxIndex - currentMinIndex < 20 ||
                currentMaxIndex >= searchWords?.length
              ) {
                extendSearchMatch(searchWords, currentMaxIndex);
                return;
              }
              performSearch(
                searchWords,
                getMiddleIndex(currentMinIndex, currentMaxIndex),
                getMiddleIndex(currentMaxIndex, currentMaxIndex * 2)
              );
            } else if (matchCount === 0) {
              performSearch(
                searchWords,
                currentMinIndex,
                getMiddleIndex(currentMinIndex, currentMaxIndex)
              );
            }
          }
        });
      };

      const searchWords = splitStringByWhitespaces(questionText);
      performSearch(searchWords, 0, searchWords?.length);
    }
  }, [questionText]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentColorTheme = useMemo(() => {
    switch (colorScheme) {
      case "light":
      case "dark":
        return colorScheme;
      default:
        return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light";
    }
  }, [colorScheme]);

  return (
    <Worker workerUrl="/pdf.worker.min.js">
      <div
        className="h-screen
        [&_.rpv-default-layout\_\_container]:border-0
        [&_.rpv-default-layout\_\_toolbar]:!border-0
        [&_.rpv-default-layout\_\_toolbar]:!bg-transparent
        [&_.rpv-default-layout\_\_toolbar]:!h-24
        [&_.rpv-default-layout\_\_body]:!bg-transparent
        [&_.rpv-default-layout\_\_body]:!pt-24"
      >
        {selectedDocument && isDocumentLoaded ? (
          <Viewer
            fileUrl={url}
            withCredentials={true}
            httpHeaders={{ Authorization: `Bearer ${LocalStorageService.getAuthToken()}` }}
            theme={currentColorTheme}
            plugins={[defaultLayoutPluginInstance, searchPluginInstance]}
            renderError={(error) => (
              <div className="w-full h-full flex flex-col justify-center items-center">
                {isTheFirstDocumentLoad && error.name === "InvalidPDFException" ? (
                  <p>
                    <span className="animate-pulse">Loading document preview...</span>
                  </p>
                ) : (
                  <p>{error.message}</p>
                )}
              </div>
            )}
            onDocumentLoad={() => {}}
          />
        ) : (
          <div className="w-full h-full flex justify-center items-center pt-10">
            <Text>
              {!documents?.length ? (
                <span>Please select an existing document or upload a new one to continue</span>
              ) : !isDocumentLoaded ? (
                <span className="animate-pulse">Waiting for the document download...</span>
              ) : null}
            </Text>
          </div>
        )}
      </div>
    </Worker>
  );
};

export default ReactPDFViewer;
