import ThemeService from "@/services/theme.ts";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useMantineColorScheme } from "@mantine/core";

function App() {
  const { colorScheme } = useMantineColorScheme();

  useEffect(() => {
    ThemeService.checkAndApplyTheme();
  }, [colorScheme]);

  return <Outlet />;
}

export default App;
