import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

import { LocalStorageService } from "@/services";
import { Button, LoadingOverlay, Text } from "@mantine/core";
import { IconApps, IconMail } from "@tabler/icons-react";

import { useSelector } from "react-redux";
import { getIsToolbarOpened } from "@/redux/slices";
import { NavbarSwitcher } from "@/components/common";
import { FilesTree } from "@/components/Rio/components";
import { usersApi } from "@/redux/api";
import { notifications } from "@mantine/notifications";
import { UserService } from "@/services";
import { ResponseState } from "@common/types";

const Main: React.FC = () => {
  const isToolbarOpened = useSelector(getIsToolbarOpened);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { data: userData } = usersApi.endpoints.getUserData.useQueryState(undefined);

  useEffect(() => {
    if (!LocalStorageService.getAuthToken()) {
      navigate("/sign-in");
    }
  }, [navigate]);

  const hasEditor = /\/rio\d?$/.test(pathname);

  const isSettingsPage = /\/settings$/.test(pathname);

  const handleMyDocsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    navigate("dashboard");
  };

  const setButtonColor = (isActive: boolean): string =>
    isActive ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)";

  const handleRequestEmailConfirmationClick = async () => {
    try {
      const requestEmailConfirmationResponse = await UserService.requestEmailConfirmation();
      if (requestEmailConfirmationResponse.state === ResponseState.success) {
        notifications.show({
          title: "Email confirmation sent.",
          message: `Email confirmation message will be sent to ${userData?.email}. Please check your inbox and your spam folder. If you do not receive the email within a few minutes, please contact us at support@aracor.ai. Please refresh this page after confirming your email.`,
          color: "green",
        });
      } else {
        throw new Error(requestEmailConfirmationResponse.message);
      }
    } catch {
      notifications.show({
        title: "Email confirmation failed.",
        message: `Email confirmation message could not be sent to ${userData?.email}. Please make sure the email address is correct. If you require further assistance, please contact us at support@aracor.ai.`,
        color: "red",
      });
    }
  };

  const isConfirmEmailOverlayVisible =
    userData?.is_email_confirmed === false &&
    !isSettingsPage &&
    process.env.VITE_RESTRICT_USERS_WITH_UNCONFIRMED_EMAIL === "true";

  return (
    <div className="relative h-dvh max-h-dvh w-dvw flex flex-row flex-nowrap font-['Commissioner']">
      <nav
        className={`flex flex-col justify-start ${
          isToolbarOpened ? "w-80" : "w-24 [&_span.mantine-Button-section]:-ml-4"
        } p-4 box-border bg-slate-700 transition-all`}
      >
        <NavLink to="dashboard" onClick={handleMyDocsClick}>
          {({ isActive }) => (
            <Button
              fullWidth
              justify="space-between"
              radius="lg"
              rightSection={<IconApps color="#334155" size={32} stroke={1.5} />}
              size="xl"
              variant="filled"
              color={setButtonColor(isActive)}
            >
              <span className="text-slate-700">Dashboard</span>
            </Button>
          )}
        </NavLink>
        {isToolbarOpened && hasEditor ? <FilesTree /> : null}
      </nav>
      <div className="h-full w-8 bg-slate-700">
        <div className="flex flex-col justify-center h-full w-full bg-white rounded-l-3xl">
          <NavbarSwitcher visible />
        </div>
      </div>
      <div className="relative flex flex-row h-dvh max-h-dvh w-full">
        <LoadingOverlay
          visible={isConfirmEmailOverlayVisible}
          overlayProps={{ blur: 2, radius: "md" }}
          zIndex={195}
          loaderProps={{
            children: (
              <div className="flex flex-col justify-center items-center mx-10 gap-4">
                <Text size="xl" fw={600} className="text-center">
                  Please confirm your email address to activate your account and to use the full
                  functionality of the site. If you would like us to resend the confirmation email,
                  please use the button below.
                </Text>
                <Button
                  leftSection={<IconMail size={20} />}
                  size="lg"
                  onClick={handleRequestEmailConfirmationClick}
                >
                  Confirm Email
                </Button>
              </div>
            ),
          }}
        />
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
