import api from "./api.ts";

import {
  ApiPayload,
  Criteria,
  IQaChatHistory,
  PlaybookGroup,
  ReviewCollectionSuggestion,
  SaveUserCriteriaRequest,
} from "@common/types";

export const reviewApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addUserCriteria: builder.mutation({
      query: ({ document_id, user_criteria }) => ({
        url: "review/collection/add_user_criteria",
        method: "POST",
        body: {
          document_id,
          user_criteria,
        },
      }),
      invalidatesTags: ["Criteria"],
    }),
    deleteUserCriteria: builder.mutation({
      query: (criteria_id) => ({
        url: "review/collection/delete_user_criteria",
        method: "DELETE",
        params: {
          criteria_id,
        },
      }),
      invalidatesTags: ["Criteria"],
    }),
    getMarkupCriteria: builder.query({
      query: (document_id) => ({
        url: "review/collection/get_criteria",
        params: {
          document_id,
        },
      }),
      transformResponse: (response: ApiPayload<{ criteria: string }>) =>
        response?.data.criteria ?? null,
    }),
    getIsReviewCollectionPrepared: builder.query({
      query: (collection_id) => ({
        url: "review/collection/is_prepared",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<boolean>) => response?.data ?? null,
    }),
    getPlaybookCriteria: builder.query({
      query: ({ playbook_type = "Default" }) => ({
        url: "review/collection/get_playbook_criteria",
        params: {
          playbook_type,
        },
      }),
      transformResponse: (response: ApiPayload<{ criteria: string }>) =>
        response?.data.criteria ?? null,
    }),
    getPlaybooks: builder.query({
      query: (document_id) => ({
        url: "review/collection/get_playbooks",
        params: document_id ? { document_id } : {},
      }),
      transformResponse: (response: ApiPayload<{ playbook_groups: PlaybookGroup[] }>) =>
        response?.data.playbook_groups || [],
    }),
    getReviewChatHistory: builder.query({
      query: (collection_id) => ({
        url: "review/chat/get_history",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<IQaChatHistory[]>) =>
        response?.data ? response.data : [],
      providesTags: (data) =>
        data
          ? [
              ...data.map(({ id }) => ({ type: "Review" as const, id })),
              { type: "Review", id: "LIST" },
            ]
          : [{ type: "Review", id: "LIST" }],
    }),
    getReviewSuggestions: builder.query({
      query: ({ collection_id }) => ({
        url: "review/collection/get_suggestions",
        method: "POST",
        params: {
          collection_id,
          apply_suggestions: true,
        },
      }),
      transformResponse: (
        response: ApiPayload<{
          suggestions: ReviewCollectionSuggestion[];
          document_was_changed: boolean;
        }>
      ) => ({
        suggestions: response?.data?.suggestions
          ? response.data.suggestions.sort(({ id: prevId }, { id: nextId }) => prevId - nextId)
          : [],
        documentWasChanged: response?.data?.document_was_changed || false,
      }),
      providesTags: (data) =>
        data?.suggestions
          ? [
              ...data.suggestions.map(({ id }) => ({ type: "Suggestions" as const, id })),
              { type: "Suggestions", id: "LIST" },
            ]
          : [{ type: "Suggestions", id: "LIST" }],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;
        if (data.documentWasChanged) {
          dispatch(api.util.invalidateTags(["OnlyOfficeToken"]));
        }
      },
    }),
    getUserCriteria: builder.query<Criteria[], string | void>({
      query: (contract_type) => ({
        url: "review/collection/get_user_criteria",
        params: {
          contract_type,
        },
      }),
      transformResponse: (response: ApiPayload<{ criterias: Criteria[] }>) =>
        response?.data.criterias,
      providesTags: (data) =>
        data
          ? [
              ...data.map(({ id }) => ({ type: "Criteria" as const, id })),
              { type: "Criteria", id: "LIST" },
            ]
          : [{ type: "Criteria", id: "LIST" }],
    }),
    prepareReviewCollection: builder.query({
      query: (collection_id) => ({
        url: "review/collection/prepare",
        method: "POST",
        params: {
          collection_id,
        },
      }),
    }),
    saveUserCriteria: builder.mutation<Criteria, SaveUserCriteriaRequest>({
      query: ({ criteria, document_id, name }) => ({
        url: "review/collection/save_user_criteria",
        method: "POST",
        body: {
          criteria,
          document_id,
          name,
        },
      }),
      invalidatesTags: ["Criteria"],
    }),
    updateReviewSuggestions: builder.mutation({
      query: (suggestions) => ({
        url: "review/collection/update_suggestions",
        method: "POST",
        body: suggestions,
      }),
      invalidatesTags: (_result, _error, data) =>
        data
          ? [
              ...data.map(({ id }: ReviewCollectionSuggestion) => ({
                type: "Suggestions",
                id,
              })),
            ]
          : ["Suggestions"],
    }),
  }),
});

export const {
  useAddUserCriteriaMutation,
  useGetIsReviewCollectionPreparedQuery,
  useLazyGetPlaybooksQuery,
  useLazyGetIsReviewCollectionPreparedQuery,
  useLazyGetMarkupCriteriaQuery,
  useLazyGetPlaybookCriteriaQuery,
  useLazyGetReviewSuggestionsQuery,
  useLazyGetReviewChatHistoryQuery,
  useLazyGetUserCriteriaQuery,
  useLazyPrepareReviewCollectionQuery,
  useDeleteUserCriteriaMutation,
  useSaveUserCriteriaMutation,
  useUpdateReviewSuggestionsMutation,
} = reviewApi;
