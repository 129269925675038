import { createSlice } from "@reduxjs/toolkit";

interface QaState {
  questionText: string | null;
}

const initialState: QaState = {
  questionText: null,
};

export const qaSlice = createSlice({
  name: "qa",
  initialState,
  reducers: {
    setQuestionText: (state, { payload }) => {
      state.questionText = payload;
    },
  },
  selectors: {
    getQuestionText: ({ questionText }) => questionText,
  },
});

export const { setQuestionText } = qaSlice.actions;

export const { getQuestionText } = qaSlice.selectors;

export default qaSlice.reducer;
