import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHomepage = () => {
    navigate("");
  };

  return (
    <div className="grid grid-rows-[auto_1fr] h-dvh w-dvw p-16">
      <div className="text-6xl">
        ARACOR.AI<span className="font-semibold animate-pulse">_</span>
      </div>
      <div className="place-self-center flex flex-col justify-center items-center gap-8">
        <div className="text-[8rem] leading-[8rem]">404</div>
        <div className="text-6xl">Looks like this page is unavailable</div>
        <button
          className="bg-indigo-600 px-4 py-2 text-white font-semibold"
          onClick={handleGoHomepage}
        >
          GO TO HOMEPAGE
        </button>
      </div>
    </div>
  );
};

export default NotFound;
