export interface UserSettings {
  preferred_explain_llm: string | null;
  preferred_markup_llm: string | null;
  preferred_app_llm: string | null;
  chat_mode: string | null;
}

export interface UserSettingsUpdate {
  preferred_explain_llm?: string | null;
  preferred_markup_llm?: string | null;
  preferred_app_llm?: string | null;
  chat_mode?: string | null;
  reloadSuggestions?: boolean;
}

export enum AvailableLLMs {
  GPT_4 = "gpt_4",
  GPT_4O = "gpt_4o",
  GPT_4O_MINI = "gpt_4o_mini",
  CLAUDE_3_HAIKU = "claude_3_haiku",
  CLAUDE_3_SONNET = "claude_3_sonnet",
  CLAUDE_3_OPUS = "claude_3_opus",
  CLAUDE_3_5_SONNET = "claude_3_5_sonnet",
  MISTRAL_LARGE_2 = "mistral_large_2",
  MISTRAL_NEMO = "mistral_nemo",
  LLAMA_3_1_70B_VERSATILE = "llama-3.1-70b-versatile",
}

export enum ChatMode {
  DISCOVERY_MODE = "FUN_MODE", // TODO :: rename to discovery on BE
  PRO_MODE = "PRO_MODE",
}
