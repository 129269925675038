import React from "react";
import { ActionIcon, Button, Progress } from "@mantine/core";
import { IconReload, IconX } from "@tabler/icons-react";
import {
  getChatNotification,
  getMode,
  getSelectedDocument,
  setChatNotification,
} from "@/redux/slices";
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts";
import {
  useLazyGetIsReviewCollectionPreparedQuery,
  useLazyGetReviewChatHistoryQuery,
  useLazyPrepareReviewCollectionQuery,
} from "@/redux/api";

const notificationsMap = new Map<"ghost" | "info" | "success" | "warning" | "error", string>([
  ["ghost", "bg-white"],
  ["info", "bg-gray-200"],
  ["success", "bg-green-100"],
  ["warning", "bg-orange-100"],
  ["error", "bg-red-200"],
]);

const ChatNotification: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();

  const mode = useAppSelector(getMode);

  const chatNotification = useAppSelector(getChatNotification);

  const selectedDocument = useAppSelector(getSelectedDocument);

  const [getIsReviewCollectionPrepared] = useLazyGetIsReviewCollectionPreparedQuery();

  const [getReviewChatHistory] = useLazyGetReviewChatHistoryQuery();

  const [prepareReviewCollection] = useLazyPrepareReviewCollectionQuery();

  const actionsMap = new Map([
    [
      "close",
      <ActionIcon
        key="close"
        variant="transparent"
        onClick={() => {
          dispatch(setChatNotification(null));
        }}
      >
        <IconX className="text-gray-400" />
      </ActionIcon>,
    ],
    [
      "reload",
      <Button
        key="reload"
        rightSection={<IconReload className="text-gray-400" />}
        variant="transparent"
        onClick={async () => {
          if (selectedDocument) {
            dispatch(setChatNotification(null));

            if (mode === "markup") {
              getIsReviewCollectionPrepared(selectedDocument.collection_id)
                .unwrap()
                .then(async (isPrepared) => {
                  if (!isPrepared) {
                    await prepareReviewCollection(selectedDocument.collection_id);
                  }
                })
                .finally(() => {
                  getReviewChatHistory(selectedDocument.collection_id);
                });
            }
          }
        }}
      >
        <span className="font-semibold uppercase">Retry</span>
      </Button>,
    ],
  ]);

  const { actions, message, progress, type } = chatNotification || {};

  const highlightingClass = notificationsMap.get(type || "ghost");

  return (
    <div>
      <div
        className={`grid grid-cols-[1fr_auto] gap-2 mx-4 my-2 p-4 rounded-xl animate-[fade-in-keyframe_0.5s_ease-in-out]
          ${message ? "visible" : "hidden"} ${highlightingClass}`}
      >
        <div>{message}</div>
        <div className="flex flex-row justify-between items-center">
          {actions?.length ? [...actions].map((action) => actionsMap.get(action)) : null}
        </div>
        {progress ? <Progress value={100} animated className="col-span-2" /> : null}
      </div>
    </div>
  );
});

export default ChatNotification;
