import { UserService } from "@/services";
import { ResponseState } from "@common/types";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Loader, Text } from "@mantine/core";
import { Logo } from "@/components/common";
import { useMediaQuery } from "@mantine/hooks";

const ConfirmEmail = () => {
  const [searchParams] = useSearchParams();

  const [message, setMessage] = useState("Confirming your email...");

  const [isConfirmationSuccessful, setIsConfirmationSuccessful] = useState<boolean>(false);

  const heightMatched = useMediaQuery("(min-height: 56.25em)");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const confirmEmail = async () => {
      const token = searchParams.get("token");

      if (!token) {
        setMessage("No email confirmation token provided.");
        return;
      }

      try {
        setIsLoading(true);
        const confirmEmailResponse = await UserService.confirmEmail(token);
        if (confirmEmailResponse.state === ResponseState.success) {
          setMessage("Your email address has been confirmed!");
          setIsConfirmationSuccessful(true);
        }
      } catch (error) {
        setMessage((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    };

    confirmEmail();
  }, [searchParams]);

  const inputSize = heightMatched ? "xl" : "lg";

  return (
    <section className="flex flex-col h-dvh w-dvw min-w-[400px] bg-stone-200 overflow-auto dark:bg-slate-600 px-2 md:px-6 lg:px-24">
      <header className="flex flex-row justify-start md:items-center items-start h-32">
        <Link to="/" className="py-1">
          <Logo />
        </Link>
      </header>
      <main className="flex flex-col md:flex-row overflow-hidden h-full bg-white rounded-3xl drop-shadow-2xl shadow-cyan-500/50 mb-4 md:mb-8 lg:mb-16">
        <div className="flex flex-1 justify-center items-center h-full w-full overflow-auto dark:bg-slate-700">
          <div className="flex justify-center items-center flex-col gap-4">
            <Text size="lg">{message}</Text>
            {isLoading ? <Loader className="mt-4" /> : null}
            {isConfirmationSuccessful ? (
              <Button
                radius="md"
                size={inputSize}
                variant="gradient"
                gradient={{ from: "#6ee7b7", to: "#5eead4", deg: 90 }}
                component={Link}
                to="/sign-in"
              >
                Proceed to Sign In
              </Button>
            ) : null}
          </div>
        </div>
        <div className="hidden md:block flex-1 bg-gradient-to-tr from-blue-600 via-teal-300 to-sky-600 bg-[length:400%_400%] animate-live-gradient" />
      </main>
      <div className="absolute top-14 md:top-1/4 right-0 md:right-6 lg:right-24">
        <div className="bg-gradient-to-tr from-blue-600 via-teal-300 to-sky-600 bg-[length:400%_400%] animate-live-gradient rounded-l-3xl shadow-md">
          <div className="p-4 md:p-16">
            <p className="text-xl md:text-4xl text-white font-semibold text-opacity-65">
              Confirm Email
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ConfirmEmail;
