import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { ActionIcon, Loader, Menu, ScrollArea, Textarea, Skeleton, Tooltip } from "@mantine/core";
import {
  IconChevronDown,
  IconStar,
  IconStarOff,
  IconSend2,
  IconStarFilled,
} from "@tabler/icons-react";
import api, {
  questionsApi,
  useAddFavoriteQuestionMutation,
  useRemoveFavoriteQuestionMutation,
} from "@/redux/api";
import { Question } from "@common/types";
import { useAppSelector } from "@/redux/hooks.ts";
import { getSelectedDocument } from "@/redux/slices";
import { Controller, UseFormReturn } from "react-hook-form";

interface ExplainInputProps {
  disabled: boolean;
  form: UseFormReturn<{ question: string }>;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onSendQuery: () => void;
  processingMessage?: string;
}

export const ExplainInput = forwardRef<HTMLTextAreaElement, ExplainInputProps>(
  ({ disabled, form, onKeyDown, onSendQuery, processingMessage }, ref) => {
    const {
      control,
      formState: { isDirty },
      getValues,
      setValue,
    } = form;
    const innerRef = useRef<HTMLTextAreaElement>(null);

    const currentQuestionId = useRef<number>(0);

    const selectedDocument = useAppSelector(getSelectedDocument);

    const {
      data: favoriteQuestionsData,
      isLoading: isFavoriteQuestionsLoading,
      isFetching: isFavoriteQuestionsFetching,
      fulfilledTimeStamp: favoriteQuestionsFulfilledTimeStamp,
    } = questionsApi.endpoints.getFavoriteQuestions.useQueryState(undefined);

    const {
      data: suggestedQuestionsData,
      isLoading: isSuggestedQuestionsLoading,
      isFetching: isSuggestedQuestionsFetching,
    } =
      // @ts-expect-error : should be described as a generic type
      api.endpoints.getSuggestedQuestions.useQueryState({
        collection_id: selectedDocument?.collection_id,
      });

    const [addFavoriteQuestion, { isLoading: isAddFavoriteQuestionLoading }] =
      useAddFavoriteQuestionMutation();

    const [removeFavoriteQuestion, { isLoading: isRemoveFavoriteQuestionLoading }] =
      useRemoveFavoriteQuestionMutation();

    const [theSameQuestions, setTheSameQuestions] = useState<
      Map<string, { isSelected: boolean; id?: number }>
    >(new Map());

    useEffect(() => {
      if (favoriteQuestionsData && suggestedQuestionsData) {
        const theSameQuestions = suggestedQuestionsData?.reduce(
          (acc: Map<string, { isSelected: boolean; id?: number }>, value: string) => {
            const isTheSameQuestion = favoriteQuestionsData.find(
              ({ question }: Question) => question === value
            );
            acc.set(value, { isSelected: !!isTheSameQuestion, id: isTheSameQuestion?.id });
            return acc;
          },
          new Map()
        );

        setTheSameQuestions(theSameQuestions);
      }
    }, [favoriteQuestionsFulfilledTimeStamp]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(
      ref,
      () => innerRef.current
    );

    const handleAddFavoriteQuestion = () => {
      const question = getValues("question");

      if (question) {
        addFavoriteQuestion({ question });
      }
    };

    const handleQuestionClick = (question: string) => {
      setValue("question", question, { shouldDirty: true });
    };

    const handleRemoveFavoriteQuestion = (event: React.MouseEvent<HTMLDivElement>, id: number) => {
      event.stopPropagation();
      currentQuestionId.current = id;
      removeFavoriteQuestion({ question_id: id });
    };

    const handleSendFavoriteQuestion = (
      event: React.MouseEvent<HTMLDivElement>,
      question: string
    ) => {
      event.stopPropagation();

      if (question) {
        setValue("question", question);
        onSendQuery();
      }
    };

    const handleSuggestedQuestionStarClick = async (
      event: React.MouseEvent<HTMLDivElement>,
      question: string,
      id?: number
    ) => {
      event.stopPropagation();

      if (question) {
        if (id) {
          await removeFavoriteQuestion({ question_id: id });
        } else {
          await addFavoriteQuestion({ question: question });
        }
      }
    };

    const favoriteQuestions = favoriteQuestionsData?.map(({ id, question }: Question) => {
      return (
        <Tooltip key={id} label="Click to copy to input area below" openDelay={1000}>
          <Menu.Item
            key={id}
            onClick={() => handleQuestionClick(question)}
            leftSection={
              isRemoveFavoriteQuestionLoading && currentQuestionId.current === id ? (
                <Loader size="xs" />
              ) : null
            }
            rightSection={
              <div className="max-h-6 flex items-center invisible group-hover:visible">
                <Tooltip label="Send" openDelay={1000}>
                  <div
                    className="hover:bg-indigo-200 rounded-sm"
                    onClick={(event) => handleSendFavoriteQuestion(event, question)}
                  >
                    <IconSend2 color="plum" />
                  </div>
                </Tooltip>
                <Tooltip label="Remove from Favorites" openDelay={1000}>
                  <div
                    className="hover:bg-indigo-200 rounded-sm"
                    onClick={(event) => handleRemoveFavoriteQuestion(event, id)}
                  >
                    <IconStarOff color="plum" />
                  </div>
                </Tooltip>
              </div>
            }
            className="group"
          >
            {question}
          </Menu.Item>
        </Tooltip>
      );
    });

    const suggestedQuestions = useMemo(
      () =>
        suggestedQuestionsData?.map((question: string, index: number) => {
          const id = theSameQuestions.has(question)
            ? theSameQuestions.get(question)?.id
            : undefined;

          return (
            <Menu.Item
              key={index}
              rightSection={
                <div onClick={(event) => handleSuggestedQuestionStarClick(event, question, id)}>
                  {id ? <IconStarFilled color="violet" /> : <IconStar color="violet" />}
                </div>
              }
              onClick={() => handleQuestionClick(question)}
            >
              {question}
            </Menu.Item>
          );
        }),
      [suggestedQuestionsData, theSameQuestions] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const isFavoriteQuestionsEmpty = !Array.isArray(favoriteQuestions) || !favoriteQuestions.length;

    const isSuggestedQuestionsEmpty =
      !Array.isArray(suggestedQuestions) || !suggestedQuestions.length;

    return (
      <Controller
        name="question"
        control={control}
        render={({ field }) => (
          <div className="bg-white rounded-md">
            <Textarea
              {...field}
              ref={innerRef}
              size="lg"
              radius="md"
              placeholder="Ask RIO"
              autosize
              maxRows={4}
              className="
                [&_.mantine-Textarea-input]:border-none
                [&_.mantine-Input-section]:w-28 [&_.mantine-TextInput-input]:!pr-28
                [&_::-webkit-scrollbar]:w-0.5 [&_::-webkit-scrollbar-thumb]:!bg-slate-400"
              onKeyDown={onKeyDown}
            />
            <div className="grid grid-cols-[1fr_auto] h-9 px-0.5">
              <div className="flex gap-2 justify-self-start justify-start items-center opacity-50 pl-4">
                {processingMessage ? (
                  <>
                    <Loader size="xs" />
                    {processingMessage}
                  </>
                ) : null}
              </div>
              <div className="flex flex-row gap-2 justify-end">
                <Menu width={400} position="top-start" shadow="xl">
                  <Menu.Target>
                    <ActionIcon size="lg" variant="subtle">
                      <IconChevronDown color="mediumPurple" />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>
                      <div className="flex flex-row justify-start items-center gap-2 font-semibold uppercase">
                        <span>Favorite Questions</span>
                        {isFavoriteQuestionsFetching ? <Loader size="xs" /> : null}
                      </div>
                    </Menu.Label>
                    <ScrollArea.Autosize mih={200} mah={400} type="auto">
                      {isFavoriteQuestionsLoading ? (
                        <>
                          {[...Array(4)].map((_, index) => (
                            <Menu.Item key={index}>
                              <Skeleton height={8} radius="xl" />
                            </Menu.Item>
                          ))}
                        </>
                      ) : isFavoriteQuestionsEmpty ? (
                        <Menu.Label>
                          <span className="italic">No Favorite Questions added yet</span>
                        </Menu.Label>
                      ) : (
                        favoriteQuestions
                      )}
                      <Menu.Divider />
                      <Menu.Label>
                        <div className="flex flex-row justify-start items-center gap-2 font-semibold uppercase">
                          <span>Suggested Questions</span>
                          {isSuggestedQuestionsFetching ? <Loader size="xs" /> : null}
                        </div>
                      </Menu.Label>
                      {isSuggestedQuestionsLoading ? (
                        <>
                          {[...Array(4)].map((_, index) => (
                            <Menu.Item key={index}>
                              <Skeleton height={8} radius="xl" />
                            </Menu.Item>
                          ))}
                        </>
                      ) : isSuggestedQuestionsEmpty ? (
                        <Menu.Label>
                          <span className="italic">No Suggested Questions available yet</span>
                        </Menu.Label>
                      ) : (
                        suggestedQuestions
                      )}
                    </ScrollArea.Autosize>
                  </Menu.Dropdown>
                </Menu>
                <Tooltip label="Add to Favorite Questions" openDelay={1000}>
                  <ActionIcon
                    disabled={!isDirty}
                    size="lg"
                    variant="subtle"
                    className="data-[disabled=true]:!bg-transparent"
                    onClick={handleAddFavoriteQuestion}
                  >
                    <IconStar
                      color={isDirty ? "mediumPurple" : "lightGray"}
                      className={isAddFavoriteQuestionLoading ? "animate-spin" : ""}
                    />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Send to RIO" openDelay={1000}>
                  <ActionIcon
                    disabled={disabled || !isDirty}
                    variant="filled"
                    size="lg"
                    radius="md"
                    color="mediumPurple"
                    aria-label="Send"
                    className="hover:!bg-violet-300 data-[disabled=true]:!border-none"
                    onClick={onSendQuery}
                  >
                    <IconSend2 color="white" />
                  </ActionIcon>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
);
