import React from "react";
import ReactMarkdown from "react-markdown";
import { ActionIcon, Kbd, Tooltip } from "@mantine/core";
import { EFontSize, IQaChatReference } from "@common/types";
import { useAppDispatch } from "@/redux/hooks.ts";
import { setQuestionText } from "@/redux/slices/qa.ts";
import { IconRefresh } from "@tabler/icons-react";
import { useLazyGetChatMessageReferencesQuery } from "@/redux/api";
import { useSelector } from "react-redux";
import { getSelectedDocument } from "@/redux/slices";
interface ChatBotMessageProps {
  references?: IQaChatReference[] | null;
  stream?: boolean;
  fontSize?: EFontSize;
  id?: number;
}

const ChatBotMessage: React.FC<React.PropsWithChildren<ChatBotMessageProps>> = ({
  children,
  references,
  stream,
  fontSize = EFontSize.base,
  id,
}) => {
  const appDispatch = useAppDispatch();

  const selectedDocument = useSelector(getSelectedDocument);

  const [getChatMessageReferences, { isFetching: isGetChatMessageReferencesFetching }] =
    useLazyGetChatMessageReferencesQuery();

  const handleRegenerateReferencesClick = () => {
    if (id) {
      getChatMessageReferences({
        message_id: id,
        collection_id: selectedDocument?.collection_id,
        refresh: true,
      });
    }
  };

  const streamingClassNames = stream
    ? "[overflow-wrap:anywhere] first:after:content-['❚'] first:after:animate-pulse first:after:text-white"
    : "[overflow-wrap:anywhere]";

  const formatAnswerAndReferences = (
    answer: string,
    references: IQaChatReference[] | null | undefined
  ) => {
    try {
      if (!Array.isArray(references) || !references.length) {
        return answer;
      }
      // Need to sort references in ascending order to make it easier to number them in ascending
      // order and to more easily inject them into the answer message later
      const sortedReferences = getSortedReferences(references);
      // Create a map which maps reference locations to reference number and text object list
      const locationReferenceMap = createLocationReferenceMap(sortedReferences);
      // Format and inject custom references into the answer message in order of location index
      return injectReferencesIntoAnswer(answer, locationReferenceMap);
    } catch (error) {
      console.error("Error formatting answer and references:");
      console.error(error);
      return answer;
    }
  };

  const getSortedReferences = (references: IQaChatReference[]) => {
    // Sort each reference location array in ascending order
    // Need to re-create each reference object because the originals are read only
    for (let reference of references) {
      reference = {
        ...reference,
        location_indices_in_message: [...reference.location_indices_in_message].sort(
          (a, b) => a - b
        ),
      };
    }

    // Sort references by first location index
    // Need to re-create the array because the original is read only
    references = [...references].sort(
      (a, b) => a.location_indices_in_message[0] - b.location_indices_in_message[0]
    );
    return references;
  };

  const createLocationReferenceMap = (
    references: IQaChatReference[]
  ): Map<number, { referenceNumber: number; referenceText: string }[]> => {
    const locationReferenceMap = new Map();
    let currentReferenceNumber = 1;
    for (const reference of references) {
      for (const referenceLocation of reference.location_indices_in_message) {
        if (locationReferenceMap.has(referenceLocation)) {
          locationReferenceMap.get(referenceLocation).push({
            referenceNumber: currentReferenceNumber,
            referenceText: reference.referenced_text,
          });
        } else {
          locationReferenceMap.set(referenceLocation, [
            {
              referenceNumber: currentReferenceNumber,
              referenceText: reference.referenced_text,
            },
          ]);
        }
      }
      currentReferenceNumber += 1;
    }
    // Return the map sorted by location index
    return new Map([...locationReferenceMap.entries()].sort((a, b) => a[0] - b[0]));
  };

  const injectReferencesIntoAnswer = (
    answer: string,
    locationReferenceMap: Map<number, { referenceNumber: number; referenceText: string }[]>
  ) => {
    let currentIndexOffset = 0;
    let answerMessageWithReferences = answer;
    for (const [referenceLocation, referenceList] of locationReferenceMap) {
      for (const reference of referenceList) {
        // Replace spacing, newline, tab etc. characters with a single space in reference text
        const referenceText = reference.referenceText.replace(/\s+/g, " ");
        // Escape brackets in reference text so that it can be used in a Markdown link
        const escapedReferenceText = referenceText.replace(/[[\]()]/g, "\\$&");
        const referenceLink = `[${escapedReferenceText}](reference#${reference.referenceNumber})`;
        const referenceLocationWithOffset = referenceLocation + currentIndexOffset;
        answerMessageWithReferences =
          answerMessageWithReferences.slice(0, referenceLocationWithOffset) +
          referenceLink +
          answerMessageWithReferences.slice(referenceLocationWithOffset);
        currentIndexOffset += referenceLink.length;
      }
    }
    return answerMessageWithReferences;
  };

  const handleShowMoreClick = (text: string): void => {
    appDispatch(setQuestionText(text));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const AnchorHandler = ({ href, children }: { href?: string; children?: any }) => {
    // If the 'href' is our custom reference (in the format of `reference#<number>`),
    // render our custom component
    if (href?.startsWith("reference#")) {
      const referenceNumber = href.split("#")[1];
      const referenceText = children ? children : "";
      return (
        <Kbd className="cursor-pointer mx-0.5" onClick={() => handleShowMoreClick(referenceText)}>
          {referenceNumber}
        </Kbd>
      );
    }
    // Otherwise render as a normal link which opens in a new tab
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  const fontSizeClassMap: Record<EFontSize, string> = {
    xs: "prose-xs",
    sm: "prose-sm",
    base: "prose-base",
    lg: "prose-lg",
    xl: "prose-xl",
  };

  return children ? (
    <div className="grid grid-cols-[1fr_auto] bg-white rounded-tr-xl rounded-br-xl rounded-bl-xl p-2 group/bot-message">
      <div
        className={`prose ${fontSizeClassMap[fontSize || "base"]} prose-strong:text-[--mantine-color-text] text-[color:var(--mantine-color-text)] pl-1 py-1`}
      >
        <ReactMarkdown
          className={streamingClassNames}
          components={{
            pre: ({ children }) => <p>{children}</p>,
            code: ({ children }) => <>{children}</>,
            p: ({ children }) => <span>{children}</span>,
            a: AnchorHandler,
          }}
        >
          {formatAnswerAndReferences(children as string, references)}
        </ReactMarkdown>
      </div>
      {id ? (
        <div className="invisible group-hover/bot-message:visible self-start">
          <Tooltip label="Regenerate references" openDelay={1000}>
            <ActionIcon variant="subtle" radius="md" onClick={handleRegenerateReferencesClick}>
              <IconRefresh
                color="blueviolet"
                className={isGetChatMessageReferencesFetching ? "animate-spin" : ""}
              />
            </ActionIcon>
          </Tooltip>
        </div>
      ) : null}
    </div>
  ) : stream ? (
    <div className="animate-pulse">❚</div>
  ) : null;
};

export default ChatBotMessage;
