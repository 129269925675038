import React from "react";
import { Button, Menu, Tabs as MantineTabs, TextInput } from "@mantine/core";
import {
  IconArrowsSort,
  IconSearch,
  IconSortAscendingLetters,
  IconSortAscendingNumbers,
  IconSortDescendingLetters,
  IconSortDescendingNumbers,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { getSortOrder } from "@/redux/slices";
import { Control, Controller } from "react-hook-form";
import { SelectedFileTreeItem } from "@common/types";

type SelectedRecord = Record<string, SelectedFileTreeItem>;

type FormValues = {
  search: string;
  selected: SelectedRecord;
};

interface ControlsProps {
  control: Control<FormValues>;
  onSortChange: (value: string) => void;
}

export const Tabs: React.FC<ControlsProps> = ({ control, onSortChange }) => {
  const sortOrder = useSelector(getSortOrder);

  return (
    <div className="flex flex-row justify-between items-end h-20 min-h-20 max-h-20">
      <div className="flex-grow">
        <MantineTabs.List>
          <MantineTabs.Tab value="files">
            <span className="uppercase font-bold text-xl">Files</span>
          </MantineTabs.Tab>
          <MantineTabs.Tab value="folders">
            <span className="uppercase font-bold text-xl">Folders</span>
          </MantineTabs.Tab>
        </MantineTabs.List>
      </div>
      <div className="flex-grow flex flex-row justify-end items-center gap-2 pb-1.5 border-b-2">
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              placeholder="Search"
              rightSection={<IconSearch stroke={1.5} color="#8b5cf6" />}
            />
          )}
        />
        <Menu>
          <Menu.Target>
            <Button
              color="#8b5cf6"
              variant="transparent"
              size="md"
              leftSection={<IconArrowsSort stroke={1.5} color="#334155" />}
            >
              <span className="text-slate-700 pr-2">Sort by:</span>
              {sortOrder.split("-").join(" (") + ")"}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>
              <span className=" font-semibold text-gray-600">FILE NAME</span>
            </Menu.Label>
            <Menu.Item
              leftSection={<IconSortAscendingLetters />}
              onClick={() => onSortChange("name-asc")}
            >
              Ascending
            </Menu.Item>
            <Menu.Item
              leftSection={<IconSortDescendingLetters />}
              onClick={() => onSortChange("name-desc")}
            >
              Descending
            </Menu.Item>
            <Menu.Divider />
            <Menu.Label>
              <span className=" font-semibold text-gray-600">UPLOADED</span>
            </Menu.Label>
            <Menu.Item
              leftSection={<IconSortAscendingNumbers />}
              onClick={() => onSortChange("uploaded-asc")}
            >
              Ascending
            </Menu.Item>
            <Menu.Item
              leftSection={<IconSortDescendingNumbers />}
              onClick={() => onSortChange("uploaded-desc")}
            >
              Descending
            </Menu.Item>
            <Menu.Divider />
            <Menu.Label>
              <span className=" font-semibold text-gray-600">EDITED</span>
            </Menu.Label>
            <Menu.Item
              leftSection={<IconSortAscendingNumbers />}
              onClick={() => onSortChange("edited-asc")}
            >
              Ascending
            </Menu.Item>
            <Menu.Item
              leftSection={<IconSortDescendingNumbers />}
              onClick={() => onSortChange("edited-desc")}
            >
              Descending
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  onSortChange: () => {},
};
