import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Logo from "@/components/Logo";
import { LocalStorageService } from "@/services";
import { useGetUserDataQuery } from "@/redux/api";

export const Header: React.FC = React.memo(() => {
  const isMiddleScreen = useMediaQuery("(min-width: 640px)");

  const { isSuccess } = useGetUserDataQuery(undefined);

  const navigate = useNavigate();

  const handleGoDashboardClick = () => {
    navigate("/rio/dashboard");
  };

  const handleRegisterClick = () => {
    navigate("/sign-up");
  };

  const handleLoginClick = () => {
    navigate("/sign-in");
  };

  const isLoggedIn = !!LocalStorageService.getAuthToken() && isSuccess;

  return (
    <div
      className="grid grid-cols-1 justify-items-center h-dvh xl:h-[50rem] w-full arc-main-background rounded-b-5xl overflow-hidden bg-fixed p-4 lg:p-8 xl:p-16 shadow-inner"
      style={{ backgroundSize: "400% 400%" }}
    >
      <div className="absolute arc-gradient-wave-1"></div>
      <div className="absolute arc-gradient-wave-2"></div>
      <div className="absolute arc-gradient-wave-3"></div>
      <div className="flex flex-col h-full w-full max-w-[1280px] bg-white/50 dark:bg-slate-700/50 rounded-3xl backdrop-blur-xl dark:bg-opacity-65 shadow-xl">
        <div className="flex flex-row justify-between items-center gap-2 h-24 px-4 sm:px-8 py-6 bg-white/85 dark:bg-slate-700 rounded-t-3xl">
          <div className="flex flex-row justify-center items-center">
            <Logo />
          </div>
          <div className="flex flex-row justify-between items-center gap-0.5">
            {isLoggedIn ? (
              <Button
                variant="gradient"
                gradient={{ from: "#67e8f9", to: "#2dd4bf", deg: 90 }}
                size={isMiddleScreen ? "lg" : "sm"}
                radius="md"
                onClick={handleGoDashboardClick}
              >
                Go to the Dashboard
              </Button>
            ) : (
              <>
                <Button
                  variant="transparent"
                  color="#2dd4bf"
                  size={isMiddleScreen ? "lg" : "sm"}
                  radius="md"
                  onClick={handleRegisterClick}
                >
                  Register
                </Button>
                <Button
                  variant="gradient"
                  gradient={{ from: "#67e8f9", to: "#2dd4bf", deg: 90 }}
                  size={isMiddleScreen ? "lg" : "sm"}
                  radius="md"
                  onClick={handleLoginClick}
                >
                  Login
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="flex md:hidden flex-col justify-center items-center h-full w-full">
          <div className="px-2 py-4 text-center text-7xl sm:text-8xl leading-none text-slate-600 dark:text-inherit">
            <span>Legal</span>
            <br />
            <span>simplified.</span>
          </div>
          <span className="p-2 w-96 text-center text-xl sm:text-2xl text-slate-600">
            Gen-AI enabled contract review software for startups and their lawyers. Currently by
            invitation - only. Register now to get on our waitlist and learn more.
          </span>
        </div>
        <div className="hidden md:flex flex-col h-full w-full">
          <div className="flex-1 flex flex-row justify-end items-start w-full">
            <span className="m-8 w-96 text-xl sm:text-2xl text-slate-600">
              Gen-AI enabled contract review software for startups and their lawyers. Currently by
              invitation - only. Register now to get on our waitlist and learn more.
            </span>
          </div>
          <div className="flex-1 flex flex-row justify-start items-end w-full">
            <div className="m-8 text-9xl lg:text-[10rem] text-slate-600 dark:text-inherit">
              <span>Legal</span>
              <br />
              <span>simplified.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Header;
