import React from "react";
import { ActionIcon, Checkbox, Tooltip } from "@mantine/core";
import { IconDownload, IconEraser, IconTrash } from "@tabler/icons-react";
import dayjs from "dayjs";
import { FileTreeItem, SelectedFileTreeItem } from "@common/types";
import { UseFormRegister } from "react-hook-form";

type SelectedRecord = Record<string, SelectedFileTreeItem>;

type FormValues = {
  search: string;
  selected: SelectedRecord;
};

interface DocumentProps {
  document: FileTreeItem;
  register: UseFormRegister<FormValues>;
  onDocumentClick: (document: FileTreeItem) => void;
  onDownloadClick: (document: FileTreeItem) => void;
  onDeleteClick: (document: FileTreeItem) => void;
}

export const File: React.FC<DocumentProps> = React.memo(
  ({ document, register, onDeleteClick, onDocumentClick, onDownloadClick }) => {
    return (
      <div
        key={document.id}
        className="grid grid-cols-[2rem_1fr_4rem_4rem_6rem_10rem] justify-start items-center gap-4
                  p-4 mb-0.5
                  bg-slate-100 hover:bg-transparent
                  border-b-2 border-slate-200
                  group"
      >
        <div className="z-0">
          <Checkbox
            variant="outline"
            size="lg"
            {...register(`selected.${document.id}.isSelected`)}
          />
        </div>
        <div className="justify-self-start truncate w-full">
          <Tooltip label={`${document.name}${document.document_original_extension ?? ""}`}>
            <span
              className="hover:underline cursor-pointer"
              onClick={() => onDocumentClick(document)}
            >
              {document.name}
              {document.document_original_extension ?? ""}
            </span>
          </Tooltip>
        </div>
        <div className="invisible group-hover:visible flex flex-row justify-center items-center gap-2">
          <ActionIcon size="xl" variant="transparent" onClick={() => onDownloadClick(document)}>
            <IconDownload color="gray" size="2rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="xl" variant="transparent" onClick={() => onDeleteClick(document)}>
            <IconTrash color="gray" size="2rem" stroke={1.5} />
          </ActionIcon>
        </div>
        <div className="flex flex-row justify-center items-center gap-2">
          {document.is_redacted ? (
            <Tooltip label="Redacted document, cleared from sensitive information">
              <IconEraser color="gray" size="2rem" stroke={1.5} />
            </Tooltip>
          ) : null}
        </div>
        <div className="flex flex-col justify-center items-start w-full border-l border-slate-300 pl-2">
          <span className="text-gray-500">Uploaded</span>
          <div>{dayjs(document.created_at).format("L")}</div>
        </div>
        <div className="flex flex-col justify-center items-start w-full border-l border-slate-300 pl-2">
          <span className="text-gray-500">Edited</span>
          <div>{dayjs(document.updated_at).format("L, HH:mm")}</div>
        </div>
      </div>
    );
  }
);
