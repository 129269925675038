import React, { useEffect } from "react";
import { useLocation, useNavigate, Outlet, Link } from "react-router-dom";
import Logo from "@/components/Logo";
import { Button } from "@mantine/core";
import { Footer } from "@/components/Home/components";
import { useMediaQuery } from "@mantine/hooks";

const Pages: React.FC = () => {
  const navigate = useNavigate();

  const isMiddleScreen = useMediaQuery("(min-width: 640px)");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="flex flex-col justify-center items-center min-h-dvh bg-sky-100 dark:bg-slate-600 shadow-xl font-['Commissioner']">
      <div className="flex-grow flex flex-col w-full max-w-[1280px] rounded-t-5xl pt-4 lg:pt-8 xl:pt-16 backdrop-blur-xl dark:bg-opacity-65">
        <div className="flex flex-row justify-between items-center gap-2 h-24 px-4 sm:px-8 py-6 bg-white/85 dark:bg-slate-700 rounded-t-5xl">
          <Link to="/" className="flex flex-row justify-center items-center">
            <Logo />
          </Link>
          <div className="flex flex-row justify-between items-center gap-0.5">
            <Button
              variant="transparent"
              color="#2dd4bf"
              radius="md"
              size={isMiddleScreen ? "lg" : "sm"}
              onClick={() => navigate("/sign-up")}
            >
              Register
            </Button>
            <Button
              variant="gradient"
              gradient={{ from: "#67e8f9", to: "#2dd4bf", deg: 90 }}
              radius="md"
              size={isMiddleScreen ? "lg" : "sm"}
              onClick={() => navigate("/sign-in")}
            >
              Login
            </Button>
          </div>
        </div>
        <div className="min-h-dvh overflow-auto bg-white">
          <Outlet />
        </div>
        <div className="h-16 bg-white rounded-b-5xl"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Pages;
