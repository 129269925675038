import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useForm } from "react-hook-form";
import { getSelectedDocument } from "@/redux/slices/documents.ts";
import { getIndexingStatusMessage } from "@/redux/slices";
import { useAppDispatch } from "@/redux/hooks.ts";
import { QaService } from "@/services";
import { SCROLL_BOTTOM_TOLERANCE, scrollToTheBottom } from "@/utils";
import { ActionIcon } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import {
  ChatBotMessage,
  ChatHeader,
  ChatOptionsMessage,
  ChatUserMessage,
  StreamMessage,
} from "./components";
import { ChatNotification, ExplainInput } from "@/components/Rio/components";
import { getFontSize, getMode, setChatNotification } from "@/redux/slices";
import { ChatResponseType, EFontSize, EQaMessageType, IQaChatHistory } from "@common/types";
import {
  qaApi,
  useChatMutation,
  useLazyCheckAndPrepareCollectionQuery,
  useLazyGetChatHistoryQuery,
  useLazyGetChatMessageReferencesQuery,
  useLazyGetSuggestedQuestionsQuery,
} from "@/redux/api";

// @ts-expect-error : type LazyQueryTrigger exists
import type { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";

const Explain: React.FC = () => {
  const appDispatch = useAppDispatch();

  const isMarkupMode = useSelector(getMode) === "markup";

  const fontSize = useSelector(getFontSize);

  const selectedDocument = useSelector(getSelectedDocument);

  const indexingStatusMessage = useSelector(getIndexingStatusMessage);

  const boundGetChatMessageReferences = useRef<LazyQueryTrigger<
    typeof getChatMessageReferences
  > | null>(null);

  const chatScrollableContainer = useRef<HTMLDivElement>(null);

  const chatTempContainerRef = useRef<HTMLDivElement>(null);

  const explainInputRef = useRef<HTMLTextAreaElement>(null);

  const isAutoScrollEnabled = useRef<boolean>(true);

  const lastStreamQueryRef = useRef<string>("");

  const [isRequestProcessing, setIsRequestProcessing] = useState<boolean>(false);

  const [isStreamError, setIsStreamError] = useState<boolean>(false);

  const [isWaitingForStream, setIsWaitingForStream] = useState<boolean>(false);

  const [stream, setStream] = useState<ReadableStreamDefaultReader<Uint8Array> | null>(null);

  const [sendChatMessage, { isLoading: isChatLoading }] = useChatMutation();

  const [checkAndPrepareCollection, { isFetching: isCheckAndPrepareCollectionFetching }] =
    useLazyCheckAndPrepareCollectionQuery();

  const [
    getChatHistory,
    {
      currentData: chat,
      isFetching: isChatHistoryFetching,
      isSuccess: isChatHistorySuccess,
      originalArgs: chatHistoryOriginalArgs,
    },
  ] = useLazyGetChatHistoryQuery();

  const [getChatMessageReferences, { isFetching: isGetChatMessageReferencesFetching }] =
    useLazyGetChatMessageReferencesQuery();

  const [getSuggestedQuestions] = useLazyGetSuggestedQuestionsQuery();

  const form = useForm<{
    question: string;
  }>({
    defaultValues: {
      question: "",
    },
  });

  const { collection_id } = selectedDocument ?? {};

  useEffect(() => {
    if (isAutoScrollEnabled.current) {
      scrollToTheBottom(chatScrollableContainer);
    }
  }, [chat]);

  useEffect(() => {
    if (collection_id) {
      checkAndPrepareCollection(collection_id)
        .unwrap()
        .then((isCollectionPrepared: unknown) => {
          if (isCollectionPrepared) {
            getChatHistory(collection_id);
            getSuggestedQuestions({ collection_id });
          }
        });
    }
  }, [collection_id, isMarkupMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const isChatHistoryReady = isChatHistorySuccess && chatHistoryOriginalArgs === collection_id;

  const isInputDisabled =
    isRequestProcessing || isCheckAndPrepareCollectionFetching || isMarkupMode;

  const resizeChatContainerCallback = useCallback((entries: ResizeObserverEntry[]) => {
    if (entries.length) {
      const chatContainer = entries[0];
      const { scrollTop, scrollHeight, clientHeight } = chatContainer.target;

      if (
        isAutoScrollEnabled.current &&
        scrollTop + clientHeight >= scrollHeight - SCROLL_BOTTOM_TOLERANCE
      ) {
        scrollToTheBottom(chatScrollableContainer);
      }
    }
  }, []);

  useEffect(() => {
    const chatTempContainer: React.MutableRefObject<HTMLDivElement> =
      chatTempContainerRef as React.MutableRefObject<HTMLDivElement>;

    const resizeObserver = new ResizeObserver(resizeChatContainerCallback);

    if (chatTempContainer.current) {
      resizeObserver.observe(chatTempContainer.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChatUserMessageCopyClick = useCallback(
    (question: string) => {
      form.setValue("question", question, { shouldDirty: true });
    },
    [form]
  );

  const handleDocumentQuestionClick = async (question: string) => {
    form.setValue("question", question);

    await handleSendQuery();
  };

  const handleTextInputKeyDown = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      await handleSendQuery();
    }
  };

  const handleSendQuery = async () => {
    if (isRequestProcessing || isInputDisabled || !collection_id) {
      return;
    }

    const query = isStreamError ? lastStreamQueryRef.current : form.getValues("question");

    if (query) {
      setIsStreamError(false);
      setIsRequestProcessing(true);

      form.reset();
      lastStreamQueryRef.current = query as string;

      const chatResponse = await sendChatMessage({
        collection_id,
        query,
      }).unwrap();

      if (chatResponse) {
        const { chat_response_type, full_response_url, response_message } = chatResponse;

        switch (chat_response_type) {
          case ChatResponseType.JSON:
            appDispatch(
              qaApi.util.updateQueryData(
                "getChatHistory",
                collection_id,
                (draft: IQaChatHistory[]) => {
                  draft.push({ ...response_message });
                }
              )
            );
            setIsRequestProcessing(false);
            break;
          case ChatResponseType.TEXT_STREAM:
            if (full_response_url) {
              boundGetChatMessageReferences.current = getChatMessageReferences.bind(this, {
                message_id: response_message.id,
                collection_id,
              });
              setIsWaitingForStream(true);
              setStream(
                await QaService.stream_chat(full_response_url, () => {
                  setIsStreamError(true);
                  setIsRequestProcessing(false);
                })
              );
              setIsWaitingForStream(false);
            } else {
              console.error("Full response URL is missing");
            }

            break;
          default:
            console.error("Unknown chat response type:", chatResponse.chat_response_type);
        }
      }
    }
  };

  const handleOnTypingDone = async () => {
    await boundGetChatMessageReferences.current();

    setIsRequestProcessing(false);
  };

  let message;

  if (isChatHistoryFetching) {
    message = "Loading chat history...";
  } else if (isCheckAndPrepareCollectionFetching) {
    message = indexingStatusMessage || "Preparing documents...";
  } else {
    appDispatch(setChatNotification(null));
  }

  if (message) {
    appDispatch(
      setChatNotification({
        message,
      })
    );
  }

  let processingState;

  if (stream && isGetChatMessageReferencesFetching) {
    processingState = "Compiling references...";
  } else if (isChatLoading) {
    processingState = "Processing your request...";
  } else if (isWaitingForStream) {
    processingState = "Preparing response...";
  } else if (isRequestProcessing) {
    processingState = "Typing response...";
  } else {
    processingState = undefined;
  }

  return (
    <div className="grid grid-rows-[auto_auto_1fr_auto] h-full min-h-full w-full min-w-full bg-gradient-to-br from-indigo-500 to-sky-400 rounded-l-xl">
      <ChatHeader />
      <ChatNotification />
      <div
        ref={chatScrollableContainer}
        onScroll={debounce(() => {
          const { scrollTop, scrollHeight, clientHeight } =
            chatScrollableContainer.current as HTMLDivElement;

          isAutoScrollEnabled.current =
            scrollTop + clientHeight > scrollHeight - SCROLL_BOTTOM_TOLERANCE;
        }, 100)}
        className="
          flex flex-col gap-3 flex-1
          overflow-y-auto mx-4
          [-webkit-scrollbar]:w-0.5 [-webkit-scrollbar-thumb]:!bg-slate-400"
      >
        {isChatHistoryReady
          ? chat?.map(({ message, message_type, references, additional_data, id }) =>
              message_type === EQaMessageType.RESPONSE_WITH_OPTIONS ? (
                <ChatOptionsMessage
                  key={id}
                  fontSize={fontSize as EFontSize}
                  message={message}
                  options={additional_data?.options}
                  onClick={handleDocumentQuestionClick}
                />
              ) : message_type === EQaMessageType.USER ? (
                <ChatUserMessage
                  key={id}
                  fontSize={fontSize as EFontSize}
                  onCopyClick={handleChatUserMessageCopyClick}
                >
                  {message}
                </ChatUserMessage>
              ) : (
                <ChatBotMessage
                  key={id}
                  id={id}
                  fontSize={fontSize as EFontSize}
                  references={references}
                >
                  {message}
                </ChatBotMessage>
              )
            )
          : null}
        <div ref={chatTempContainerRef}>
          {stream && isRequestProcessing ? (
            <div className="flex flex-col gap-4">
              <StreamMessage
                reader={stream}
                fontSize={fontSize as EFontSize}
                onTypingDone={handleOnTypingDone}
              />
            </div>
          ) : null}
        </div>
        {isStreamError ? (
          <div className="flex flex-row justify-between items-center bg-red-300 rounded-tr-xl rounded-br-xl rounded-bl-xl">
            <span className="p-3 font-semibold">Chat response error, please try again.</span>
            <ActionIcon className="mx-2" variant="transparent" onClick={handleSendQuery}>
              <IconRefresh color="black" />
            </ActionIcon>
          </div>
        ) : null}
      </div>
      <div className="sticky bottom-0 p-4">
        <ExplainInput
          ref={explainInputRef}
          form={form}
          disabled={isInputDisabled}
          processingMessage={processingState}
          onKeyDown={handleTextInputKeyDown}
          onSendQuery={handleSendQuery}
        />
      </div>
    </div>
  );
};

export default Explain;
