import { createBrowserRouter } from "react-router-dom";
import App from "@/App.tsx";
import Home from "@/components/Home";
import SignInWithGoogle from "@/components/SignInWithGoogle.tsx";
import NotAuthorized from "@/components/NotAuthorized.tsx";
import ConfirmEmail from "@/components/ConfirmEmail";
import { Layout, Dashboard, Settings, Rio } from "@/components/Rio";
import { Pages, Privacy, SignIn, SignUp, Tos } from "@/components";
import NotFound from "@/NotFound.tsx";
import ResetPassword, { EmailToResetForm, ResetPasswordForm } from "@/components/ResetPassword";

export default createBrowserRouter([
  {
    path: "*",
    Component: NotFound,
  },
  {
    path: "",
    Component: App,
    children: [
      {
        children: [
          { Component: Layout, path: "" },
          {
            Component: Dashboard,
            path: "dashboard/:entity?",
          },
          {
            Component: Settings,
            path: "settings",
          },
        ],
        Component: Rio,
        path: "/rio",
      },
      {
        path: "",
        Component: Home,
      },
      {
        path: "pages",
        Component: Pages,
        children: [
          { path: "tos", Component: Tos },
          { path: "privacy", Component: Privacy },
        ],
      },
    ],
  },
  {
    path: "reset-password",
    Component: ResetPassword,
    children: [
      { path: "request", Component: EmailToResetForm },
      { path: "", Component: ResetPasswordForm },
    ],
  },
  {
    path: "sign-in",
    Component: SignIn,
  },
  {
    path: "sign-up",
    Component: SignUp,
  },
  {
    path: "auth/google/callback",
    Component: SignInWithGoogle,
  },
  {
    path: "not-authorized",
    Component: NotAuthorized,
  },
  {
    path: "confirm-email",
    Component: ConfirmEmail,
  },
]);
