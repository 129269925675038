import React, { memo } from "react";
import { ActionIcon, Tooltip } from "@mantine/core";
import { IconEdit, IconStar, IconStarFilled } from "@tabler/icons-react";
import { useAddFavoriteQuestionMutation } from "@/redux/api";

import { EFontSize } from "@common/types";

interface ChatUserMessageProps {
  fontSize?: EFontSize;
  onCopyClick: (text: string) => void;
}

const ChatUserMessage: React.FC<React.PropsWithChildren<ChatUserMessageProps>> = memo(
  ({ children, fontSize = EFontSize.base, onCopyClick = () => {} }) => {
    const fontSizeClass = `text-${fontSize}`;

    const [addFavoriteQuestion, { isLoading, isSuccess }] = useAddFavoriteQuestionMutation();

    const handleAddFavoriteQuestion = () => {
      if (children) {
        addFavoriteQuestion({ question: children as string });
      }
    };

    return (
      <div className="grid grid-cols-[1fr_auto] gap-2 p-4 bg-white/65 rounded-t-xl rounded-bl-xl [overflow-wrap:anywhere] group">
        <span className={fontSizeClass}>{children}</span>
        <div>
          <Tooltip label="Copy to input area" openDelay={1000}>
            <ActionIcon
              variant="subtle"
              className="self-start justify-self-center invisible group-hover:visible cursor-pointer"
              onClick={() => onCopyClick(children as string)}
            >
              <IconEdit color="blueviolet" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Add to favorites" openDelay={1000}>
            <ActionIcon
              variant="subtle"
              className="self-end invisible group-hover:visible cursor-pointer"
              onClick={handleAddFavoriteQuestion}
            >
              {isSuccess ? (
                <IconStarFilled color="blueviolet" />
              ) : (
                <IconStar color="blueviolet" className={isLoading ? "animate-spin" : ""} />
              )}
            </ActionIcon>
          </Tooltip>
        </div>
      </div>
    );
  }
);

export default ChatUserMessage;
