import { modals } from "@mantine/modals";
import { HoverCard } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import Upload from "./Upload.tsx";
import { MODAL_ID } from "./index.ts";
import { FileTreeItem } from "@common/types";

interface OpenUploadArgs {
  isFolder: boolean;
  collection?: FileTreeItem;
}

export const openUpload = ({ isFolder, collection }: OpenUploadArgs) => {
  modals.open({
    modalId: MODAL_ID,
    title:
      isFolder && !collection?.collection_id ? (
        <div className="flex flex-row gap-1 text-2xl">
          <span>Upload Folder</span>
          <sup>
            <HoverCard>
              <HoverCard.Target>
                <IconInfoCircle color="#8b5cf6" />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <div className="max-w-72">
                  Allows grouping up documents into a folder and chatting with all of the documents
                  in the folder at the same time. Use this to easily retrieve information from
                  multiple documents, compare documents, and more.
                </div>
              </HoverCard.Dropdown>
            </HoverCard>
          </sup>
        </div>
      ) : (
        <div className="text-2xl">
          {collection?.collection_id ? "Add files to collection" : "Upload Documents"}
        </div>
      ),
    children: <Upload isFolder={isFolder} collection={collection} />,
    centered: true,
    padding: 24,
    radius: "lg",
    closeOnClickOutside: false,
    size: "auto",
  });
};
