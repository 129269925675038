import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts";
import { throttle } from "lodash";
import { getSelectedDocument } from "@/redux/slices/documents.ts";
import { handleScroll, scrollToTheBottom, SCROLL_BOTTOM_TOLERANCE } from "@/utils";
import { ChatHeader } from "./components";
import { ChatNotification } from "@/components/Rio/components";
import { getFontSize, getMode, setChatNotification, setQuestionText } from "@/redux/slices";
import { Criteria, ReviewCollectionSuggestion } from "@common/types";
import {
  reviewApi,
  useLazyGetMarkupCriteriaQuery,
  useAddUserCriteriaMutation,
  useDeleteUserCriteriaMutation,
  useGetIsReviewCollectionPreparedQuery,
  useGetUserSettingsQuery,
  useLazyGetReviewSuggestionsQuery,
  useLazyPrepareReviewCollectionQuery,
  useUpdateReviewSuggestionsMutation,
  useLazyGetPlaybookCriteriaQuery,
  useLazyGetPlaybooksQuery,
  useLazyGetUserCriteriaQuery,
} from "@/redux/api";
import {
  Accordion,
  ActionIcon,
  Button,
  Textarea,
  Menu,
  ScrollArea,
  AccordionControl,
} from "@mantine/core";
import {
  IconChevronDown,
  IconPlaylistAdd,
  IconSquareCheck,
  IconSquareX,
  IconX,
} from "@tabler/icons-react";
import { Controller, useForm } from "react-hook-form";
import { FeedbackMessage } from "@/components/Rio/components/FeedbackMessage";
import { modals } from "@mantine/modals";
import { AddUserCriteria } from "@/components/modals/AddUserCriteria";
import { MetCriteriaCard, SuggestionCard } from "@/components/Rio/components/Markup";
import { notifications } from "@mantine/notifications";

const Review: React.FC = () => {
  const dispatch = useAppDispatch();

  const selectedDocument = useAppSelector(getSelectedDocument);

  const { id: document_id } = selectedDocument ?? {};

  const fontSize = useAppSelector(getFontSize);

  const mode = useAppSelector(getMode);

  const chatScrollableContainer = useRef<HTMLDivElement>(null);

  const chatTempContainerRef = useRef<HTMLDivElement>(null);

  const [metCriteriaHighlightedId, setMetCriteriaHighlightedId] = useState<number | null>(null);

  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState<boolean>(true);

  const [getPlaybooksQuery, { data: availablePlaybooks, isSuccess: isPlaybooksGotSuccessfully }] =
    useLazyGetPlaybooksQuery();

  const { fulfilledTimeStamp: getUserSettingsFulfilledTimeStamp } = useGetUserSettingsQuery();

  const [getMarkupCriteria] = useLazyGetMarkupCriteriaQuery();

  const [getPlaybookCriteria] = useLazyGetPlaybookCriteriaQuery();

  const [getUserCriteria, { data: userCriteriaList }] = useLazyGetUserCriteriaQuery();

  const [addUserCriteria, { isLoading: isAddCriteriaLoading }] = useAddUserCriteriaMutation();

  const [deleteUserCriteria] = useDeleteUserCriteriaMutation();

  const { data: isCollectionPrepared, isSuccess: isCollectionCheckedSuccessfully } =
    useGetIsReviewCollectionPreparedQuery(selectedDocument?.collection_id, {
      refetchOnMountOrArgChange: true,
    });

  const [
    getReviewSuggestions,
    {
      currentData: suggestionResult,
      originalArgs,
      isFetching: isSuggestionsFetching,
      isError: isSuggestionsError,
    },
  ] = useLazyGetReviewSuggestionsQuery();

  const [prepareReviewCollection, { isFetching: isPrepareReviewCollectionFetching }] =
    useLazyPrepareReviewCollectionQuery();

  const [updateReviewSuggestions, { isLoading: isUpdateReviewSuggestionsLoading }] =
    useUpdateReviewSuggestionsMutation();

  const { control, getValues, setValue, reset, watch } = useForm<{
    criteriaField: string;
  }>();

  const watchCriteria = watch("criteriaField"); // you can also target specific fields by their names

  const reviewChatHistory = React.useMemo(
    () => reviewApi.endpoints.getReviewChatHistory.select(selectedDocument?.collection_id),
    [selectedDocument]
  );

  useEffect(() => {
    if (originalArgs) {
      dispatch(
        reviewApi.util.upsertQueryData(
          "getReviewSuggestions",
          { collection_id: originalArgs?.collection_id },
          { suggestions: [], documentWasChanged: false }
        )
      );
    }
  }, [selectedDocument]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { collection_id, id, document_original_extension } = selectedDocument || {};

    if (document_original_extension === ".docx") {
      (async () => {
        if (isCollectionCheckedSuccessfully) {
          if (!isCollectionPrepared) {
            await prepareReviewCollection(collection_id);
          }

          await getPlaybooksQuery(id);
          await getUserCriteria();
        }
      })();
    }
  }, [selectedDocument, isCollectionPrepared, mode, getUserSettingsFulfilledTimeStamp]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { collection_id, id } = selectedDocument || {};

    if (id && isCollectionPrepared) {
      (async () => {
        const criteria = await getMarkupCriteria(id).unwrap();

        if (criteria) {
          setValue("criteriaField", criteria);

          await getReviewSuggestions({ collection_id });
        } else if (suggestionResult?.suggestions.length) {
          reset({ criteriaField: "" });
        }
      })();
    }
  }, [selectedDocument, isCollectionPrepared]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    scrollToTheBottom(chatScrollableContainer);
  }, [reviewChatHistory]);

  const resizeChatContainerCallback = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (entries.length) {
        const chatContainer = entries[0];
        const { scrollTop, scrollHeight, clientHeight } = chatContainer.target;

        if (
          isAutoScrollEnabled &&
          scrollTop + clientHeight >= scrollHeight - SCROLL_BOTTOM_TOLERANCE
        ) {
          scrollToTheBottom(chatScrollableContainer);
        }
      }
    },
    [isAutoScrollEnabled]
  );

  useEffect(() => {
    const chatTempContainer: React.MutableRefObject<HTMLDivElement> =
      chatTempContainerRef as React.MutableRefObject<HTMLDivElement>;

    const resizeObserver = new ResizeObserver(resizeChatContainerCallback);

    if (chatTempContainer.current) {
      resizeObserver.observe(chatTempContainer.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeChatContainerCallback]);

  const handleAccordionChange = (value: string | null) => {
    const suggestionById = suggestionResult?.suggestions.find(({ id }) => id.toString() === value);

    dispatch(setQuestionText(suggestionById?.search_text ?? null));
    setMetCriteriaHighlightedId(null);
  };

  const handleAcceptSuggestion = (suggestion: ReviewCollectionSuggestion) => {
    updateReviewSuggestions([
      {
        ...suggestion,
        is_accepted: true,
        is_ignored: false,
      },
    ]);
  };

  const handleAcceptAll = () => {
    if (suggestionsGroups) {
      updateReviewSuggestions(
        suggestionsGroups?.issues.map((suggestion) => ({
          ...suggestion,
          is_accepted: true,
          is_ignored: false,
        }))
      );
    }
  };

  const handleDeclineSuggestion = (suggestion: ReviewCollectionSuggestion) => {
    updateReviewSuggestions([
      {
        ...suggestion,
        is_accepted: false,
        is_ignored: true,
      },
    ]);
  };

  const handleDeclineAll = () => {
    if (suggestionsGroups) {
      updateReviewSuggestions(
        suggestionsGroups?.issues.map((suggestion) => ({
          ...suggestion,
          is_accepted: false,
          is_ignored: true,
        }))
      );
    }
  };

  const handleAddUserCriteria = async () => {
    const criteria = getValues("criteriaField");

    if (selectedDocument) {
      await addUserCriteria({
        collection_id: selectedDocument.collection_id,
        document_id: selectedDocument.id,
        user_criteria: criteria,
      });

      await getReviewSuggestions({ collection_id: selectedDocument.collection_id });
    }
  };

  const handleApplyUserCriteria = async (name: string, criteria: string) => {
    setValue("criteriaField", criteria);

    notifications.show({
      title: "Criteria Loaded",
      message: `Criteria from playbook "${name}" have been loaded.`,
      color: "green",
    });
  };

  const handleDeleteUserCriteria = (
    event: React.MouseEvent<HTMLButtonElement>,
    { id, name }: Criteria
  ) => {
    const currentTagName = event.currentTarget.tagName.toLowerCase();

    if (currentTagName === "svg" || currentTagName === "button") {
      modals.openConfirmModal({
        title: <span className="text-xl">Delete Custom Playbook</span>,
        children: <p>Are you sure you would like to delete the playbook named "{name}"?</p>,
        labels: { confirm: "Delete", cancel: "Cancel" },
        confirmProps: { color: "violet" },
        onConfirm: () => {
          notifications.show({
            id: "DELETE_CUSTOM_PLAYBOOK_IN_PROGRESS",
            loading: true,
            title: "Deleting",
            message: `Deleting playbook "${name}"...`,
          });
          deleteUserCriteria(id);
          setValue("criteriaField", "");
          notifications.hide("DELETE_CUSTOM_PLAYBOOK_IN_PROGRESS");
          notifications.show({
            title: "Playbook Deleted",
            message: `Playbook "${name}" has been deleted.`,
            color: "green",
          });
        },
      });
    }
  };

  const handleGetPlaybookCriteria = async (playbookType: string) => {
    notifications.show({
      id: "GET_PLAYBOOK_CRITERIA_IN_PROGRESS",
      loading: true,
      title: "Loading",
      message: `Loading criteria for playbook "${playbookType}"...`,
    });

    const playbookCriteria = await getPlaybookCriteria({
      playbook_type: playbookType,
    }).unwrap();

    if (playbookCriteria) {
      setValue("criteriaField", playbookCriteria);
    }

    notifications.hide("GET_PLAYBOOK_CRITERIA_IN_PROGRESS");

    notifications.show({
      title: "Criteria Loaded",
      message: `Criteria from playbook "${playbookType}" have been loaded.`,
      color: "green",
    });
  };

  const handleMetCriteriaClick = useCallback(
    ({ search_text, id }: ReviewCollectionSuggestion) => {
      if (search_text || search_text !== "NA") {
        dispatch(setQuestionText(search_text));
        setMetCriteriaHighlightedId(id);
      } else {
        dispatch(setQuestionText(null));
      }
    },
    [dispatch]
  );

  const handleSaveUserCriteria = async () => {
    if (document_id) {
      modals.open({
        title: "Add Custom Playbook",
        children: <AddUserCriteria documentId={document_id} />,
      });
    }
  };

  let notification;

  if (isPrepareReviewCollectionFetching) {
    notification = {
      message: "Preparing documents...",
    };
  } else if (isSuggestionsFetching) {
    notification = {
      message: "Suggestions loading... (this may take a few minutes)",
      progress: true,
    };
  } else if (isUpdateReviewSuggestionsLoading) {
    notification = {
      message: "Updating suggestions...",
    };
  } else if (isAddCriteriaLoading) {
    notification = {
      message: "Adding new playbook...",
    };
  } else {
    notification = null;
  }

  dispatch(setChatNotification(notification));

  const isDocx = selectedDocument?.document_original_extension === ".docx";

  const isSuggestionsReady = isDocx && suggestionResult?.suggestions.length && !isSuggestionsError;

  const suggestionsGroups = suggestionResult?.suggestions.reduce<{
    met: ReviewCollectionSuggestion[];
    issues: ReviewCollectionSuggestion[];
  }>(
    (acc, currentValue) => {
      if (currentValue.type_of_change == "NoChange") {
        acc.met.push(currentValue);
      } else {
        acc.issues.push(currentValue);
      }

      return acc;
    },
    { met: [], issues: [] }
  );

  const fontSizeClass = `text-${fontSize}`;

  return (
    <div className="grid grid-rows-[auto_auto_auto_1fr] h-full min-h-full w-full min-w-full bg-indigo-500 rounded-l-xl pb-2">
      <ChatHeader />
      {isDocx ? (
        <div className="grid grid-rows-[1fr_auto] gap-0.5 px-4">
          <Controller
            name="criteriaField"
            control={control}
            render={({ field }) => (
              <Textarea
                {...field}
                autosize
                color="white"
                label={<span className="text-lg text-white">Criteria</span>}
                minRows={3}
                maxRows={4}
                placeholder="Enter your criteria here. Then click the button below to load suggestions"
                radius="md"
                size="lg"
                className="[&_.mantine-Textarea-wrapper]:bg-transparent [&_textarea]:font-['Commissioner']"
              />
            )}
          />
          <div className="flex flex-row justify-end items-center gap-1 p-1">
            <Menu position="bottom-end" shadow="md" zIndex={150}>
              <Menu.Target>
                <Button
                  disabled={!isPlaybooksGotSuccessfully}
                  variant="outline"
                  color="white"
                  rightSection={<IconChevronDown />}
                >
                  Playbooks
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <ScrollArea h={300} type="auto" className="pr-3">
                  <Menu.Item leftSection={<IconPlaylistAdd />} onClick={handleSaveUserCriteria}>
                    Add Custom Playbook
                  </Menu.Item>
                  {userCriteriaList?.length ? (
                    <>
                      <Menu.Divider />
                      <Menu.Label style={{ color: "#8b5cf6" }}>Custom Playbooks</Menu.Label>
                      {userCriteriaList?.map((criteria) => {
                        const { id, name, criteria: description } = criteria;

                        return (
                          <div key={id || name} className="flex justify-between items-center gap-2">
                            <Menu.Item
                              key={criteria.name}
                              onClick={() => handleApplyUserCriteria(name, description)}
                            >
                              {name}
                            </Menu.Item>
                            <ActionIcon
                              variant="subtle"
                              size="xs"
                              color="red"
                              onClick={(event) => handleDeleteUserCriteria(event, criteria)}
                            >
                              <IconX />
                            </ActionIcon>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                  {availablePlaybooks?.length ? (
                    <>
                      <Menu.Divider />
                      <Menu.Label style={{ color: "#8b5cf6" }}>Predefined Playbooks</Menu.Label>
                      {availablePlaybooks
                        ?.slice()
                        .sort((a, b) =>
                          a.is_highlighted === b.is_highlighted ? 0 : a.is_highlighted ? -1 : 1
                        )
                        .map(({ contract_type, playbooks }) => (
                          <>
                            <Menu.Label style={{ paddingLeft: "1.5rem" }}>
                              {contract_type}
                            </Menu.Label>
                            {playbooks.map((playbookName) => (
                              <Menu.Item
                                key={playbookName}
                                style={{ paddingLeft: "2rem" }}
                                onClick={() => handleGetPlaybookCriteria(playbookName)}
                              >
                                {playbookName}
                              </Menu.Item>
                            ))}
                          </>
                        ))}
                    </>
                  ) : null}
                </ScrollArea>
              </Menu.Dropdown>
            </Menu>

            <Button variant="white" disabled={!watchCriteria} onClick={handleAddUserCriteria}>
              Get suggestions
            </Button>
          </div>
        </div>
      ) : (
        <div className="bg-violet-100 p-4 m-4 rounded-xl">
          This functionality is available for DOCX files only
        </div>
      )}
      <ChatNotification />
      {isSuggestionsReady ? (
        <>
          <div
            ref={chatScrollableContainer}
            onScroll={throttle(
              () => handleScroll(chatScrollableContainer, setIsAutoScrollEnabled),
              250
            )}
            className={`relative flex flex-col flex-1 overflow-y-auto mx-4 ${fontSizeClass}`}
          >
            <div className="sticky top-0 min-h-4 bg-gradient-to-b from-indigo-500 to-transparent z-10" />
            <Accordion unstyled className={suggestionsGroups?.met.length ? "" : "hidden"}>
              <Accordion.Item value="met">
                <AccordionControl className="grid grid-cols-[1fr_auto] gap-2 [&_.mantine-Accordion-chevron]:self-center [&_.mantine-Accordion-chevron]:order-last bg-white p-4 my-2 rounded-b-2xl rounded-tr-2xl w-full hover:bg-indigo-100">
                  <div className="grid grid-cols-[auto_1fr] gap-2 row-span-2">
                    <div>
                      <IconSquareCheck color="green" />
                    </div>
                    <div className="justify-self-start flex flex-col justify-start items-start">
                      <div className="pb-2 font-semibold">
                        {suggestionsGroups?.met.length} criteria met
                      </div>
                      <div className="text-start">
                        Click here to see which criteria are already met by the document.
                      </div>
                    </div>
                  </div>
                </AccordionControl>
                <Accordion.Panel className="pl-4">
                  {suggestionsGroups?.met.map((criteria) => (
                    <MetCriteriaCard
                      key={criteria.id}
                      criteria={criteria}
                      isHighlighted={metCriteriaHighlightedId === criteria.id}
                      onClick={handleMetCriteriaClick}
                    />
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <Accordion unstyled defaultValue="issues">
              <Accordion.Item value="issues">
                <AccordionControl className="grid grid-cols-[1fr_auto] gap-2 [&_.mantine-Accordion-chevron]:self-center [&_.mantine-Accordion-chevron]:order-last bg-white p-4 my-2 rounded-b-2xl rounded-tr-2xl w-full hover:bg-indigo-100">
                  <div className="grid grid-cols-[auto_1fr] gap-2 row-span-2">
                    <div>
                      <IconSquareX color="rgb(153 27 27)" />
                    </div>
                    <div className="justify-self-start flex flex-col justify-start items-start">
                      <div className="pb-2 font-semibold">
                        {suggestionsGroups?.issues.length} issues found
                      </div>
                      <div className="text-start">
                        Click here to check suggestions on how to fix them. Suggestions can be
                        accepted or declined.
                      </div>
                    </div>
                  </div>
                </AccordionControl>
                <Accordion.Panel className="pl-4">
                  <Accordion
                    chevron={<IconChevronDown color="#8b5cf6" />}
                    className="[&_.mantine-Accordion-chevron]:w-6 [&_.mantine-Accordion-chevron]:m-0.5 w-full"
                    onChange={handleAccordionChange}
                  >
                    {suggestionsGroups?.issues.map((suggestion) => (
                      <SuggestionCard
                        key={suggestion.id}
                        suggestion={suggestion}
                        onAccept={handleAcceptSuggestion}
                        onDecline={handleDeclineSuggestion}
                      />
                    ))}
                  </Accordion>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <FeedbackMessage />
            <div className="grid grid-cols-2 gap-2 pt-4">
              <Button variant="light" color="white" onClick={handleAcceptAll}>
                <span className="text-white text-lg">Accept all</span>
              </Button>
              <Button variant="light" color="white" onClick={handleDeclineAll}>
                <span className="text-white text-lg">Decline all</span>
              </Button>
            </div>
            <div className="sticky bottom-0 min-h-4 bg-gradient-to-t from-indigo-500 to-transparent z-10" />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Review;
