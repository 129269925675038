import api from "./api.ts";
import { ApiPayload, PrepareCollectionResponse } from "@common/types";
import { RootState } from "@/redux/store.ts";
import { pushNotification, setIndexingStatusMessage } from "@/redux/slices";

// @ts-expect-error - need to find and apply types in right way or reorder basic api structure
export const documentsIndexApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    callPrepareCollectionStep: builder.query({
      query: (url) => ({
        url,
        method: "POST",
      }),
      transformResponse: (response: ApiPayload<PrepareCollectionResponse>) => response?.data,
    }),
    getIsCollectionPrepared: builder.query({
      query: (collection_id) => ({
        url: "index/is_collection_prepared",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<boolean>) => response?.data,
    }),
    prepareCollection: builder.query({
      query: (collection_id) => ({
        url: "index/prepare_collection",
        method: "POST",
        params: {
          collection_id,
        },
      }),
      transformResponse: (response: ApiPayload<PrepareCollectionResponse>) => response?.data,
    }),
    checkAndPrepareCollection: builder.query<boolean, string>({
      async queryFn(collection_id, { getState, dispatch }) {
        try {
          // @ts-expect-error - need to find and apply types in right way or reorder basic api structure
          const { data: isCollectionPrepared, isSuccess: isCollectionPreparedSuccess } =
            await dispatch(
              documentsIndexApi.endpoints.getIsCollectionPrepared.initiate(collection_id)
            );

          const { documents } = <RootState>getState();
          const isRedacted = documents.selectedDocument?.is_redacted;

          if (isCollectionPreparedSuccess && !isCollectionPrepared) {
            const indexingStatusMessage = isRedacted
              ? "Redacting documents..."
              : "Processing documents...";

            dispatch(setIndexingStatusMessage(indexingStatusMessage));

            const { data: collection, isSuccess: isPrepareCollectionSuccess } = await dispatch(
              documentsIndexApi.endpoints.prepareCollection.initiate(collection_id)
            );

            let nextUrl;
            let nextName;

            if (isPrepareCollectionSuccess) {
              nextUrl = collection?.next_url;
              nextName = collection?.next_name;

              while (nextUrl) {
                if (nextName) {
                  dispatch(setIndexingStatusMessage(`${nextName}...`));
                }

                // @ts-expect-error - need to find and apply types in right way or reorder basic api structure
                const { data, isSuccess } = await dispatch(
                  documentsIndexApi.endpoints.callPrepareCollectionStep.initiate(nextUrl)
                );

                if (isSuccess) {
                  nextUrl = data?.next_url;
                  nextName = data.next_name;
                } else {
                  nextUrl = null;
                  nextName = null;
                }
              }

              // Re-fetch the user file tree if the collection was redacted. This is needed because
              // redaction might have happened during the collection pre-processing stage, and the
              // document's `redacted_url` field should be filled in / updated. If we don't do this,
              // then the user will not be able to view the redacted view of the document for
              // newly uploaded documents.
              // This file tree re-fetching might be unnecessary in the future when we implement a
              // separate redaction tab/tool.
              if (isRedacted) {
                dispatch(
                  api.util.invalidateTags([
                    { type: "Documents", id: documents.selectedDocument?.id },
                  ])
                );
              }

              return { data: true };
            }
          }

          return { data: isCollectionPrepared };
        } catch {
          dispatch(
            pushNotification({
              id: Date.now(),
              type: "error",
              message: "Failed to prepare collection",
              timestamp: Date.now(),
            })
          );

          return {
            error: {
              error:
                "Failed to process your documents.\nWould you like to try again?\nIf the problem persists, please try again later.",
              status: "CUSTOM_ERROR",
            },
          };
        } finally {
          dispatch(setIndexingStatusMessage(null));
        }
      },
    }),
  }),
});

export const { useLazyCheckAndPrepareCollectionQuery } = documentsIndexApi;
