import { createSelector, createSlice } from "@reduxjs/toolkit";
import { EDocumentsThunkAction, FileTreeItem, ThunkState, UploadStatus } from "@common/types";

export interface DocumentsState {
  documents: FileTreeItem[];
  error?: string;
  isDocumentLoaded: boolean;
  isRedactView: boolean;
  isSelectedDocumentParent: boolean;
  isSelectedDocumentRedacted: boolean;
  selectedDocument: FileTreeItem | null;
  thunkState: Record<EDocumentsThunkAction, ThunkState>;
  uploadStatus: UploadStatus;
  uploadProgress: number | null;
}

const initialState: DocumentsState = {
  documents: [],
  error: undefined,
  isDocumentLoaded: false,
  isRedactView: false,
  isSelectedDocumentParent: false,
  isSelectedDocumentRedacted: false,
  selectedDocument: null,
  thunkState: {
    deleteDocument: ThunkState.idle,
    fetchUserFiles: ThunkState.idle,
    uploadDocument: ThunkState.idle,
  },
  uploadStatus: UploadStatus.choosing,
  uploadProgress: null,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setIsDocumentLoaded: (state, { payload }) => {
      state.isDocumentLoaded = payload;
    },
    setIsRedactView: (state, { payload }) => {
      state.isRedactView = payload;
    },
    setIsSelectedDocumentParent: (state, { payload }) => {
      state.isSelectedDocumentParent = payload;
    },
    setIsSelectedDocumentRedacted: (state, { payload }) => {
      state.isSelectedDocumentRedacted = payload;
    },
    setSelectedDocument: (state, { payload }) => {
      state.selectedDocument = payload;
    },
    setUploadProgress: (state, { payload }) => {
      state.uploadProgress = payload;
    },
  },
  selectors: {
    getDocuments: ({ documents }) => documents,
    getIsDocumentsThunkStateResolved: createSelector(
      [(state) => state.thunkState, (_state, actionName: EDocumentsThunkAction) => actionName],
      (thunkState, actionName) =>
        thunkState[actionName] === ThunkState.succeeded ||
        thunkState[actionName] === ThunkState.failed
    ),
    getIsDocumentLoaded: ({ isDocumentLoaded }) => isDocumentLoaded,
    getIsRedactView: ({ isRedactView }) => isRedactView,
    getIsSelectedDocumentRedacted: ({ isSelectedDocumentRedacted }) => isSelectedDocumentRedacted,
    getSelectedDocument: ({ selectedDocument }) => selectedDocument,
    getUploadProgress: ({ uploadProgress }) => uploadProgress,
  },
});

export const {
  setIsDocumentLoaded,
  setIsRedactView,
  setIsSelectedDocumentParent,
  setIsSelectedDocumentRedacted,
  setSelectedDocument,
  setUploadProgress,
} = documentsSlice.actions;

export const {
  getDocuments,
  getIsDocumentLoaded,
  getIsRedactView,
  getIsSelectedDocumentRedacted,
  getSelectedDocument,
} = documentsSlice.selectors;

export default documentsSlice.reducer;
