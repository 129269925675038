import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { Token } from "@common/types";

import instance from "@/api.ts";
import { LocalStorageService } from "@/services";

// Component is responsible for:
// 1. taking data from Google and pass it to BE
// 2. take a newly generated auth token from BE (internal token) and store it in the local storage
const SignInWithGoogle = () => {
  const navigate = useNavigate();

  const query_string = window.location.search;

  // Ask BE to validate received from Google auth token and give us back our internal auth token
  instance
    .get("/users/auth/google/callback" + query_string)
    .then(async ({ data }: AxiosResponse<Token>) => {
      LocalStorageService.setAuthToken(JSON.stringify(data));

      return data;
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      navigate("/rio");
    });

  return null;
};

export default SignInWithGoogle;
