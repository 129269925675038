import React, { memo } from "react";

import { NavLink, Tooltip } from "@mantine/core";
import { IconFolderOpen, IconFolder, IconFileDescription } from "@tabler/icons-react";

import { FileTreeItem } from "@common/types";

interface TreeItemProps {
  document: FileTreeItem;
  isActive: boolean;
  isOpened?: boolean;
  key: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

const TreeItem: React.FC<React.PropsWithChildren<TreeItemProps>> = memo(
  ({ children, document, isOpened, isActive, key, onClick }) => {
    const isFolder = Array.isArray(document.children) && !document.parent_folder_id;
    const isChild = document.parent_folder_id;

    return (
      <NavLink
        key={key}
        label={
          <Tooltip label={`${document.name}${document.document_original_extension ?? ""}`}>
            <div className="grid grid-cols-[1fr] group">
              <div className="truncate w-full text-white">
                <span className={isChild ? "text-base" : "text-base font-semibold"}>
                  {document.name}
                  {document.document_original_extension ?? ""}
                </span>
              </div>
            </div>
          </Tooltip>
        }
        leftSection={
          isFolder ? (
            isOpened ? (
              <IconFolderOpen color="black" fill="#f8d775" stroke={1.5} />
            ) : (
              <IconFolder color="black" fill="#f8d775" stroke={1.5} />
            )
          ) : (
            <IconFileDescription color="black" fill="white" stroke={1.5} />
          )
        }
        active={isActive}
        opened={isOpened}
        childrenOffset="xl"
        onClick={onClick}
        color="rgb(163,163,163,.5)"
        variant={isFolder ? "filled" : "light"}
        className="hover:!bg-neutral-400/25"
      >
        {children}
      </NavLink>
    );
  }
);

TreeItem.defaultProps = {
  isActive: false,
  isOpened: false,
};

export default TreeItem;
