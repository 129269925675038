import axios from "axios";
import { matchPath } from "react-router";

import router from "@/router.ts";
import { LocalStorageService } from "@/services";

const api = axios.create({
  baseURL: "/api",
});

const URLS_WITHOUT_TOKEN = [
  "/users/signup",
  "/users/token",
  "/users/auth/google/callback",
  "/users/confirm_email",
];

api?.interceptors.request.use((config) => {
  const token = LocalStorageService.getAuthToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  } else if (config.url && URLS_WITHOUT_TOKEN.some((url) => config.url?.startsWith(url))) {
    return config;
  }

  throw new axios.Cancel(`Permission denied: ${config.url}`);
});

api?.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const navigate = router.navigate;
      const pathname = router.state.location.pathname;

      LocalStorageService.removeAuthToken();

      if (matchPath(pathname, "/sign-in") || matchPath(pathname, "/sign-up")) {
        return Promise.reject(error);
      }

      await navigate("/sign-in");
    }

    return Promise.reject(error);
  }
);

export default api;
