import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChatNotification, Notification, NotificationStatus } from "@common/types";

interface NotificationsState {
  chatNotification: IChatNotification | null;
  indexingStatusMessage?: string;
  notifications: Notification[];
  status: NotificationStatus;
}

const initialState: NotificationsState = {
  chatNotification: null,
  indexingStatusMessage: undefined,
  notifications: [],
  status: NotificationStatus.ghost,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setChatNotification: (state, { payload }: PayloadAction<IChatNotification | null>) => {
      state.chatNotification = payload;
    },
    setIndexingStatusMessage: (state, { payload }) => {
      state.indexingStatusMessage = payload;
    },
    pushNotification: (state, { payload }) => {
      const timestamp: number = Date.now();

      const newNotification = {
        id: timestamp,
        timestamp,
        ...payload,
      };

      state.notifications = [...state.notifications, newNotification];

      const statusPriority: Record<NotificationStatus, number> = {
        ghost: 0,
        info: 1,
        success: 2,
        warning: 3,
        error: 4,
      };

      let highestStatus: NotificationStatus = NotificationStatus.ghost;

      for (const notification of state.notifications) {
        if (statusPriority[notification.type] > statusPriority[highestStatus]) {
          highestStatus = notification.type as NotificationStatus;
        }
      }

      state.status = highestStatus;
    },
  },
  selectors: {
    getChatNotification: ({ chatNotification }) => chatNotification,
    getIndexingStatusMessage: ({ indexingStatusMessage }) => indexingStatusMessage,
  },
});

export const { pushNotification, setChatNotification, setIndexingStatusMessage } =
  notificationsSlice.actions;

export const { getChatNotification, getIndexingStatusMessage } = notificationsSlice.selectors;

export default notificationsSlice.reducer;
