import React, { useEffect } from "react";
import { useAppDispatch } from "@/redux/hooks.ts";
import { setIsDocumentLoaded } from "@/redux/slices/documents.ts";

import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  HoverCard,
  TextInput,
  ScrollArea,
  Tooltip,
} from "@mantine/core";
import { useForm, Controller } from "react-hook-form";
import { IconInfoCircle, IconCircleX } from "@tabler/icons-react";
import { humanFileSize } from "@/utils";
import { useAddDocumentsToCollectionMutation, useUploadDocumentMutation } from "@/redux/api";
import { Drop } from "@/components/Rio";
import { modals } from "@mantine/modals";
import { MODAL_ID } from "./index.ts";
import { FileTreeItem } from "@common/types";

interface UploadProps {
  isFolder?: boolean;
  collection?: FileTreeItem;
}

interface FormValues {
  folder: string;
  files: File[];
  isRedact: boolean;
}

const Upload: React.FC<UploadProps> = ({ isFolder, collection }) => {
  const appDispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      folder: "",
      files: [],
      isRedact: false,
    },
  });

  useEffect(() => {
    if (collection) {
      setValue("folder", collection.name);
    }
  }, [setValue, collection]);

  const watchFiles = watch("files", []);

  const [uploadDocument, { isLoading: isUploadDocumentLoading }] = useUploadDocumentMutation();

  const [addDocumentsToCollection, { isLoading: isAddDocumentsToCollectionLoading }] =
    useAddDocumentsToCollectionMutation();

  const closeAndResetForm = () => {
    modals.close(MODAL_ID);
    reset();
  };

  const handleRemoveFile = (fileIndex: number) => {
    const newFilesSet = getValues("files")?.filter((_file, index) => fileIndex !== index);

    if (newFilesSet) {
      setValue("files", newFilesSet);
    }
  };

  const handleUploadClick = async ({ folder, isRedact }: FormValues) => {
    const formData = new FormData();

    if (watchFiles?.length) {
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });
    }

    appDispatch(setIsDocumentLoaded(false));

    const options = folder ? { folder_name: folder, redact: isRedact } : { redact: isRedact };

    if (collection) {
      await addDocumentsToCollection({ collection_id: collection.collection_id, data: formData });
    } else {
      await uploadDocument({ data: formData, ...options });
    }

    closeAndResetForm();
  };

  const files = getValues("files");

  return (
    <>
      <div className="flex flex-col gap-2 min-h-[50dvh]">
        {process.env.VITE_IS_MULTI_CHAT_ENABLED === "true" && isFolder ? (
          <Controller
            name="folder"
            control={control}
            render={({ field }) => (
              <TextInput
                type="text"
                size="lg"
                placeholder="Enter folder name*"
                error={errors.folder?.type === "required"}
                hidden={!isFolder}
                disabled={!!collection}
                className="flex-shrink"
                {...field}
              />
            )}
            rules={{
              validate: {
                required: (value) => !!value.length,
              },
            }}
          />
        ) : null}
        <div className="flex flex-col gap-2 overflow-y-scroll">
          <ScrollArea type="auto">
            <div className="max-h-[200px] flex flex-col gap-0.5">
              {files?.length
                ? Array.from(files).map(({ name, size }, index) => (
                    <div
                      className="grid grid-cols-[1fr_6rem_6rem] items-center gap-2 h-12 min-h-12 px-3 bg-slate-100 hover:bg-transparent"
                      key={index}
                    >
                      <div className="truncate">
                        <Tooltip label={name}>
                          <span className="truncate w-full">{name}</span>
                        </Tooltip>
                      </div>
                      <div className="justify-self-end">{humanFileSize(size)}</div>
                      <div className="justify-self-end">
                        <ActionIcon
                          variant="transparent"
                          radius={50}
                          color="#f87171"
                          onClick={() => handleRemoveFile(index)}
                        >
                          <IconCircleX stroke={1.5} />
                        </ActionIcon>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </ScrollArea>
        </div>
        <Drop files={files} setValue={setValue} />
        <form onSubmit={handleSubmit(handleUploadClick)}>
          <div className="grid grid-cols-[1fr_auto] gap-6 pt-4">
            {process.env.VITE_IS_REDACTION_ENABLED === "true" && !collection?.collection_id ? (
              <>
                <Controller
                  name="isRedact"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Checkbox.Card
                      className="p-4 hover:bg-gray-100"
                      checked={value}
                      radius="md"
                      onClick={() => setValue("isRedact", !value)}
                      {...field}
                    >
                      <Group className="m-4" wrap="nowrap" align="flex-start">
                        <Checkbox.Indicator color="violet" />
                        <div>
                          <div className="flex flex-row gap-1 font-semibold">
                            Redact Documents
                            <sup>
                              <HoverCard>
                                <HoverCard.Target>
                                  <IconInfoCircle size={16} stroke={2.5} color="#8b5cf6" />
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                  <div className="max-w-72">
                                    <strong>
                                      Note: This feature is work-in-progress and may not redact all
                                      sensitive information yet.
                                    </strong>
                                    <br />
                                    <br />
                                    Redact sensitive information such as names, addresses, phone
                                    numbers, etc. from the documents you upload so that your or your
                                    client's privacy is protected from the AIs that we use to
                                    analyze the documents. You will have a chance to check the
                                    redacted version of the document before starting a conversation
                                    with the AI.
                                    <br />
                                    <br />
                                    <i>
                                      Note: Redaction of your documents takes extra resources and
                                      may noticeably slow down the workflow with your redacted
                                      documents.
                                    </i>
                                  </div>
                                </HoverCard.Dropdown>
                              </HoverCard>
                            </sup>
                          </div>
                          <div className="text-sm text-slate-600">
                            Clear documents from sensitive information
                          </div>
                        </div>
                      </Group>
                    </Checkbox.Card>
                  )}
                />
              </>
            ) : (
              <div />
            )}
            <Button
              className="self-end"
              color="#8b5cf6"
              loading={isAddDocumentsToCollectionLoading || isUploadDocumentLoading}
              disabled={!getValues("files")?.length}
              size="lg"
              type="submit"
            >
              Upload
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Upload;
