import React from "react";
import dayjs from "dayjs";
import {
  ActionIcon,
  Checkbox,
  RenderTreeNodePayload,
  Tooltip,
  Tree,
  TreeNodeData,
} from "@mantine/core";
import { IconChevronDown, IconDownload, IconTrash, IconFilePlus } from "@tabler/icons-react";
import { FileTreeItem, SelectedFileTreeItem } from "@common/types";

import { UseFormRegister } from "react-hook-form";
import { openUpload } from "@/components/modals";

type SelectedRecord = Record<string, SelectedFileTreeItem>;

type FormValues = {
  search: string;
  selected: SelectedRecord;
};

interface CollectionsProps {
  data: FileTreeItem[];
  register: UseFormRegister<FormValues>;
  onDelete: (file: FileTreeItem) => void;
  onDocumentClick: (file: FileTreeItem) => void;
  onDownload: (file: FileTreeItem) => void;
  onSelectionChange: (
    id: string,
    parentFolderId: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const collectionMapFn = (file: FileTreeItem): TreeNodeData => {
  const { id, name, children } = file;

  return {
    value: id,
    label: name,
    children: Array.isArray(children) ? children.map(collectionMapFn) : children,
    nodeProps: { file },
  };
};

export const Folders: React.FC<CollectionsProps> = ({
  data,
  register,
  onDelete,
  onDocumentClick,
  onDownload,
  onSelectionChange,
}) => {
  const collectionsTreeData = data.map(collectionMapFn);

  const renderNodeFn = React.useCallback(
    ({ node, expanded, hasChildren, elementProps }: RenderTreeNodePayload) => {
      const file = node?.nodeProps?.file ?? {};
      const { id, parent_folder_id, created_at, updated_at } = file;

      return (
        <div
          {...elementProps}
          className={`
            ${hasChildren ? "bg-slate-300 border border-gray-400 ml-0 py-4" : "bg-slate-100 ml-12 py-2"}
            grid grid-cols-[auto_1fr_auto_auto_10rem_auto] justify-start content-center gap-4 hover:bg-opacity-50 border-b border-slate-300 border-t border-t-slate-100 px-4 group
          `}
        >
          <div className="place-self-center">
            <Checkbox
              variant="outline"
              size="lg"
              {...register(`selected.${node.value}.isSelected`, {
                onChange: onSelectionChange.bind(null, id, parent_folder_id),
              })}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div
            className="justify-self-start self-center text-bottom hover:underline"
            onClick={() => onDocumentClick(file)}
          >
            {node.label}
          </div>
          <div className="invisible group-hover:visible flex flex-row justify-center items-center gap-2">
            {hasChildren ? (
              <Tooltip label="Add Documents to Folder">
                <ActionIcon
                  size="xl"
                  variant="transparent"
                  onClick={() => openUpload({ isFolder: true, collection: file })}
                >
                  <IconFilePlus color="gray" size="2rem" stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            ) : (
              <Tooltip label="Download">
                <ActionIcon size="xl" variant="transparent" onClick={() => onDownload(file)}>
                  <IconDownload color="gray" size="2rem" stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip label="Delete">
              <ActionIcon size="xl" variant="transparent" onClick={() => onDelete(file)}>
                <IconTrash color="gray" size="2rem" stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </div>
          <div className="flex flex-col justify-center items-start w-full border-l border-slate-300 pl-2">
            <span className="text-gray-500">Uploaded</span>
            <div>{dayjs(created_at).format("L")}</div>
          </div>
          <div className="flex flex-col justify-center items-start w-full border-l border-slate-300 pl-2">
            <span className="text-gray-500">Edited</span>
            <div>{dayjs(updated_at).format("L, HH:mm")}</div>
          </div>
          <div className={`${expanded ? "rotate-180" : "rotate-0"} place-self-center`}>
            {hasChildren ? <IconChevronDown /> : null}
          </div>
        </div>
      );
    },
    [onDelete, onDocumentClick, onDownload, onSelectionChange, register]
  );

  return <Tree data={collectionsTreeData} renderNode={renderNodeFn} />;
};
